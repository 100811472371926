import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (Component: any) => {
  return class WithAuth extends React.Component {
    render() {
      const token = localStorage.getItem('token');

      if (!token) {
        return <Navigate to="/" />;
      }

      return <Component {...this.props} />;
    }
  };
};

export default withAuth;
