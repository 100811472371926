import React, { useEffect, useState } from 'react';
import AuthLogoComponent from '../../../Components/CoreComponents/AuthLogoComponent';
import ForgotCardComponent from '../../../Components/CoreComponents/ForgotCardComponent';
import Loader from '../../../Components/CoreComponents/Loader';
import '../Auth.css';

const validateMessages = {
  required: '${name} is required!',
  types: {
    email: 'Please add proper ${name}',
  },
};

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  });

  const onFinish = (values: any) => {
    console.log(values);
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="auth_module">
        <AuthLogoComponent />
        <ForgotCardComponent
          formFinish={onFinish}
          validateMessages={validateMessages}
          backToLoginLink="/"
        />
      </div>
    </>
  );
};
