import { apiRoutes } from '../../apiRoutes';
import { get, post, put, remove } from '../../apiServices';

interface inviteUserInterface {
  name: string | null;
  usersToAdd: Array<string>;
}

interface updateProjectInterface {
  id: string | undefined;
  updatedProject: {
    name: string | null;
    assignedUsers: Array<string>;
  };
}

export const createProject = async (formData: inviteUserInterface) => {
  try {
    return await post(`${apiRoutes.projects.create}`, formData);
  } catch (error: any) {
    return error.response;
  }
};
export const updateProject = async (formData: updateProjectInterface) => {
  try {
    return await put(`${apiRoutes.projects.update}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const getProjects = async (search: string) => {
  try {
    return await get(`${apiRoutes.projects.get}?organization=${search}`);
  } catch (error: any) {
    return error.response;
  }
};

export const getProject = async (id: string | undefined) => {
  try {
    return await get(`${apiRoutes.projects.get}/${id}`);
  } catch (error: any) {
    return error.response;
  }
};

export const getProjectList = async (projectId: string | undefined) => {
  try {
    return await get(`${apiRoutes.projects.list}${projectId}`);
  } catch (error: any) {
    console.log(error)
    return error.response;
  }
};

export const getProjectUsers = async (id: string | undefined) => {
  try {
    return await get(`${apiRoutes.projects.get}/${id}/users`);
  } catch (error: any) {
    return error.response;
  }
};

export const deleteProject = async (id: string | undefined) => {
  try {
    return await remove(`${apiRoutes.projects.delete}${id}`);
  } catch (error: any) {
    return error.response;
  }
};
