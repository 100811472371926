import React, { useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//API
import { changePassword, logOut } from '../../Network/Core/Auth/AuthServices';

interface FormValues {
  oldPassword: string;
  newPassword: string;
  rePassword: string;
}

const ChangePasswordForm = ({ validateMessages }: any) => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const envPasswordPattern: string | undefined =
    process.env.REACT_APP_PASSWORD_REGEX;
  const passwordPattern: string =
    typeof envPasswordPattern === 'string' ? envPasswordPattern : '';

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const [formData, setFormData] = useState<FormValues>({
    oldPassword: '',
    newPassword: '',
    rePassword: '',
  });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getPasswordRe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsFormValid(false);
    if (formData.oldPassword !== '' && formData.newPassword !== '') {
      try {
        await changePassword({
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword
        });
        await logOut();
        localStorage.clear();
        navigate('/');
        toast.success('Please login with new password');
      } catch (error: any) {
        toast.error('Something went wrong');
        setIsFormValid(true);
      }
    }
  };
  return (
    <Form
      className="common-form change_pwd_form"
      layout="vertical"
      form={form}
      name="change-password"
      onSubmitCapture={onSubmit}
      validateMessages={validateMessages}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item
            name="Old Password"
            rules={[
              { required: true, message: 'Old password is required' },
              {
                pattern: new RegExp(passwordPattern),
                message: process.env.REACT_APP_PASSWORD_MESSAGE,
              },
            ]}
          >
            <Input.Password
              name="oldPassword"
              onChange={handleFormChange}
              placeholder="Enter Old Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'New Password is required!',
              },
              {
                pattern: new RegExp(passwordPattern),
                message: process.env.REACT_APP_PASSWORD_MESSAGE,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              name="newPassword"
              onChange={handleFormChange}
              placeholder="Enter New Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item
            name="rePassword"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Re Password is required!',
              },
              () => ({
                validator(_, passValue) {
                  if (!passValue || formData.newPassword === passValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        'The new password that you entered do not match!',
                      ),
                    );
                  }
                },
              }),
            ]}
          >
            <Input.Password
              name="rePassword"
              placeholder="Re-enter New Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="change-pwd-btn">
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Change password
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePasswordForm;
