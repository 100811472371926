import React from 'react';

//Components
import AuthLogoComponent from '../../../Components/CoreComponents/AuthLogoComponent';
import LoginCardComponent from '../../../Components/CoreComponents/LoginCardComponent';

//CSS
import '../Auth.css';

const validateMessages = {
  required: '${name} is required!',
  types: {
    email: 'Please add proper ${name}',
  },
};

export const Login = () => {
  return (
    <>
      <div className="auth_module">
        <AuthLogoComponent />
        <LoginCardComponent
          cardTitle={'Login'}
          validateMessages={validateMessages}
          forgotLink="/forgot-password"
        />
      </div>
    </>
  );
};
