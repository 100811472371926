import React, {  useState } from 'react';
import images from '../../../Assets/Images';
import { Card, Button, Form, Input } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

//Components
import AuthLogoComponent from '../../../Components/CoreComponents/AuthLogoComponent';

//API
import { setPassword } from '../../../Network/Core/Auth/AuthServices';

//CSS files
import '../Auth.css';

const validateMessages = {
  required: '${name} is required!',
  types: {
    email:
      '$export const Login = (title: any) => {{name} is not a valid email!',
  },
};
interface FormValues {
  token: string | null;
  newPassword: string;
  email: string | null;
  confirmPassword: string;
}

export const CreatePassword = () => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  const envPasswordPattern: string | undefined =
    process.env.REACT_APP_PASSWORD_REGEX;
  const passwordPattern: string =
    typeof envPasswordPattern === 'string' ? envPasswordPattern : '';

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormValues>({
    token: '',
    newPassword: '',
    email: searchParams.get('email'),
    confirmPassword: '',
  });
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const token = searchParams.get('token');
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      token: token,
    });
  };
  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormValid(false);
    const token = searchParams.get('token');

    if (token) {
      if (
        formData.newPassword !== '' &&
        formData.email !== '' &&
        formData.confirmPassword !== ''
      ) {
        try {
          await setPassword(formData);
          toast.success('Password changed successfully');
          navigate('/');
        } catch (error: any) {
          setIsFormValid(true);
        }
        setIsFormValid(true);
      }
    } else {
      toast.error('Token is not valid. please click on link again');
      setIsFormValid(true);
    }
  };
  return (
    <>
      <div className="auth_module">
        <AuthLogoComponent />
        <Card className="Auth_card forgot-card" style={{ width: '469px' }}>
          <div className="forgot-icon">
            <img src={images.Key} alt="key" className="key" />
          </div>
          <h3>Create Password</h3>
          {/* <p>Admin has invited you! Create your password.</p> */}
          <Form
            name="create-password-form"
            onSubmitCapture={handleReset}
            validateMessages={validateMessages}
            form={form}
          >
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: 'New password is required!' },
                {
                  pattern: new RegExp(passwordPattern),
                  message: process.env.REACT_APP_PASSWORD_MESSAGE,
                },
              ]}
              hasFeedback
            >
              <Input.Password
                onChange={handleFormChange}
                name="newPassword"
                placeholder="Enter New Password"
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeInvisibleFilled />
                }
              />
            </Form.Item>
            <Form.Item
              name="ConfirmPassword"
              hasFeedback
              rules={[
                { required: true, message: 'Confirm password is required!' },
                () => ({
                  validator(_, passValue) {
                    if (!passValue || formData.newPassword === passValue) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          'The new password that you entered do not match!',
                        ),
                      );
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Re-enter New password"
                name="confirmPassword"
                onChange={handleFormChange}
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeInvisibleFilled />
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                Create password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};
