import React from 'react';
import { AppRoutes } from './AppRoutes';
import './App.css';
import './Assets/Css/Common.css';
import ToasterComponent from './Components/UI/toaster';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <ToasterComponent />
    </div>
  );
}

export default App;
