export const apiRoutes = {
  auth: {
    login: 'auth/login',
    changePassword: 'users/password',
    forgetPassword: 'auth/reset-password',
    setPassword: 'auth/new-password',
    refreshToken: 'auth/refresh-token',
    logout: 'auth/logout',
  },
  organization: {
    list: 'organizations',
    invite: 'organizations',
    update: 'organizations',
    delete: 'organizations/',
  },
  user: {
    list: 'users',
    pending: 'users/pending',
    get: 'users',
    invite: 'users',
    update: 'users',
    details: 'users/me',
    delete: 'users/',
    patch: 'users/',
    register: 'users/register',
  },
  projects: {
    list: 'projects/list/',
    get: 'projects',
    update: 'projects',
    details: 'projects/me',
    delete: 'projects/',
    create: 'projects',
  },
  directory: {
    list: 'files/directories/list/',
    get: 'files/',
    update: 'files/',
    details: 'projects/me',
    delete: 'projects/',
    create: 'files/directories',
    uploadFile: 'files/drawings',
    deleteFile: 'files/',
    exportAsBuilt: 'files/'
  },
};
