import React from 'react';
import { Layout } from 'antd';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';

import AddProjectFormComponent from '../../../Components/CoreComponents/AddProjectFormComponent';

//CSS
import '../ProjectList/ProjectList.css';

const { Content } = Layout;

export const AddProject = () => {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="projects-list" />
          <Content>
            <PageContentHeader
              headerTitle="Add Project"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <AddProjectFormComponent />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
