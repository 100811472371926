import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';
import {updateFile} from '../../Network/Core/Directory/DirectoryServices'

//Images
import images from '../../Assets/Images';

interface FormValues {
    name: string | null;
}

const EditFileFormComponent = ({ FormTitle, file }: any) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormValues>({
        name: file.name,
    });
    const [form] = Form.useForm();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setIsFormValid(true);
            },
            () => {
                setIsFormValid(false);
            },
        );
    }, [values]);

    useEffect(() => {
        setFormData({
            name: file.name
        });
    }, []);


    const saveFile = async () => {
        if (formData.name !== '') {
            try {
                setIsFormValid(false);
                await updateFile(file.id, formData);
                toast.success('File updated successfully');
                navigate(-1);
            } catch (error: any) {
                if (error.response?.status === 400) {
                    toast.error(error.response.data.error);
                    setIsFormValid(true);
                }
            }
        } else {
            toast.error('Fill Required Fields');
        }
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await saveFile();
    };

    const backToFileList = () => {
        navigate(-1);
    };


    return (
        <Form
            className="common-form"
            layout="vertical"
            form={form}
            onSubmitCapture={handleSubmit}
        >
            <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
                <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                >
                    <FormItemInputComponent
                        label="Name"
                        name="name"
                        className="name"
                        placeholder="Enter Name"
                        inputType="text"
                        suffix={<img src={images.LegalName} alt="name" />}
                        onChangeFunction={handleFormChange}
                        rules={[
                            { required: true, message: 'File Name is required' },
                            {
                                max: 50,
                                message: 'File Name must be max 50 characters.',
                            },
                            {
                                pattern: new RegExp(
                                    '^[A-Za-z0-9- _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
                                ),
                                message: 'Special character not allowed',
                            },
                        ]}
                        initialValues={formData.name}
                    />
                </Col>
            </Row>

            <Form.Item>
                <div className="bottom-btn-section">
                    <Button className="btn-link" onClick={() => backToFileList()}>
                        Cancel
                    </Button>
                    {FormTitle === 'add-file' ? (
                        <Button
                            className="common-btn"
                            htmlType="submit"
                            disabled={!isFormValid}
                        >
                            Send invite
                        </Button>
                    ) : (
                        <Button
                            className="common-btn"
                            htmlType="submit"
                            disabled={!isFormValid}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};

export default EditFileFormComponent;
