import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthUser from './Utils/user';
interface Props {
  component: React.ComponentType;
  path?: string;
  role?: Array<string>;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  role: roles,
}) => {
  const isAuthenticated = AuthUser.isAuthenticated();
  const hasAccess = AuthUser.hasAccess(roles);
  if (isAuthenticated && hasAccess) {
    return <RouteComponent />;
  }

  return <Navigate to="/" />;
};
