import { decrypt } from 'n-krypta';
import { toast } from 'react-toastify';

const getToken = () => {
  return localStorage.getItem('token');
};

const getSecret = () => {
  return 'CITRUSBUG-LIGHTYX';
};
const getLocalRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};
const isAuthenticated = () => {
  return !!getToken();

};
const getAuthUser = () => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};

const getPassword = () => {
  return decrypt(localStorage.getItem('access_code') || '', getSecret());
};
const updateLocalAccessToken = (response: any) => {
  const jwt_token = 'Bearer ' + response.data.tokens.accessToken.token;
  localStorage.setItem('token', jwt_token);
  localStorage.setItem(
    'expirationDate',
    response.data.tokens.accessToken["expirationDate"],
  );
  localStorage.setItem('refreshToken', response.data.tokens.refreshToken.token);
  localStorage.setItem('user', JSON.stringify(response.data.user));
};

const getRedirectPath = () => {
  const user = getAuthUser();

  if (user && user.role === 'owner') {
    return '/organization-list';
  } else if (user && user.role === 'admin') {
    //admin = organization
    return '/projects-list';
  } else if (user && user.role === 'user') {
    return '/dashboard';
  }
  return '/';
};

const hasAccess = (roles: any) => {
  const user = getAuthUser();
  if (!roles) {
    return true;
  }

  if (roles.length > 0 && roles.includes(user?.role)) {
    return true;
  } else {
    toast.error('You have not access to this page.');
  }
  return false;
};

const isOwner = () => {
  const user = getAuthUser();
  return !!(user.role === 'admin' &&
      localStorage.getItem('access_code') &&
      localStorage.getItem('is_owner'));

};

const AuthUser = {
  getAuthUser,
  isAuthenticated,
  getToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  getSecret,
  getPassword,
  getRedirectPath,
  hasAccess,
  isOwner,
};

export default AuthUser;
