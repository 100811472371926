import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, useMap, GeoJSON } from 'react-leaflet';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import L from 'leaflet';
import { Layout, Breadcrumb, Button } from 'antd';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getFile, exportAsBuilt } from '../../../Network/Core/Directory/DirectoryServices';


//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';

//API
import axios from 'axios';

//CSS
import './ProjectDetail.css';
import './Map.css';
import AuthUser from '../../../Utils/user';
import Loader from '../../../Components/CoreComponents/Loader';
import { toast } from 'react-toastify';

const { Content } = Layout;

interface LayerElement {
  name: string;
  color: string;
  visible: boolean;
  category: string;
}

interface Data extends FeatureCollection {
  layers: LayerElement[];
}

type JsonValue = string | number | boolean | null | JsonObject | Array<JsonValue>;
interface JsonObject {
  [key: string]: JsonValue;
}

// Function to normalize coordinates
const normalizeCoordinates = (geojson: Data): Data => {
  // Find bounding box
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  const updateBounds = (coordinates: [number, number, number?][]) => {
    coordinates.forEach(([x, y]) => {
      if (x < minX) minX = x;
      if (y < minY) minY = y;
      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
    });
  };

  geojson.features.forEach((feature) => {
    switch (feature.geometry.type) {
      case 'LineString':
        updateBounds(feature.geometry.coordinates as [number, number, number?][]);
        break;
      case 'Point':
        updateBounds([feature.geometry.coordinates as [number, number, number?]]);
        break;
      case 'MultiLineString':
        feature.geometry.coordinates.forEach(line => updateBounds(line as [number, number, number?][]));
        break;
    }
  });

  // Translate and scale coordinates to fit into [-180, 180] and [-90, 90]
  const rangeX = maxX - minX;
  const rangeY = maxY - minY;
  const scaleX = 360 / rangeX;
  const scaleY = 180 / rangeY;
  const scale = Math.min(scaleX, scaleY) * 0.001;

  // Calculate center point for translation
  const centerX = minX + rangeX / 2;
  const centerY = minY + rangeY / 2;

  const translateAndScale = ([x, y, z]: [number, number, number?]): [number, number, number?] => [
    (x - centerX) * scale,
    (y - centerY) * scale,
    z
  ];

  const normalizeFeature = (feature: any): any => {
    // Store the original coordinates in a new property
    feature.properties.originalCoordinates = feature.geometry.coordinates;

    switch (feature.geometry.type) {
      case 'LineString':
        feature.geometry.coordinates = feature.geometry.coordinates.map(translateAndScale);
        break;
      case 'Point':
        feature.geometry.coordinates = translateAndScale(feature.geometry.coordinates);
        break;
      case 'MultiLineString':
        feature.geometry.coordinates = feature.geometry.coordinates.map((line: [number, number, number?][]) => line.map(translateAndScale));
        break;
    }
    return feature;
  };

  const normalizedGeojson = { ...geojson };
  normalizedGeojson.features = normalizedGeojson.features
    .filter((f) => {return f.properties && !f.properties.removed})
    .map(normalizeFeature);
  return normalizedGeojson;
};

const MyData = ({file}: any) => {
  if (!file) {
    return
  }
  const [data, setData] = React.useState<Data>();
  const [layerVisibility, setLayerVisibility] = useState<Record<string, boolean>>({});
  const [loaded, setLoaded] = useState<boolean>(false)
  const map = useMap();
  const geoJsonLayer = useRef<L.GeoJSON | null>(null); // Reference to the GeoJSON layer

  const drawingUrl = file.simplifiedGeojson;
  const asBuiltUrl = file.asBuilt;

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(drawingUrl);
      const asBuiltResponse = asBuiltUrl ? await axios.get(asBuiltUrl) : {data: {features: [], layers: []}};

      // in case the as built file is empty
      if (!asBuiltResponse.data.layers) {
        asBuiltResponse.data.layers = []
      }
      if (!asBuiltResponse.data.features) {
        asBuiltResponse.data.layers = []
      }

      // Merge features from both datasets
      const combinedFeatures = [...response.data.features, ...asBuiltResponse.data.features];
      const combinedLayers = [...response.data.layers, ...asBuiltResponse.data.layers];

      const combinedData: Data = normalizeCoordinates({
        ...response.data,
        features: combinedFeatures,
        layers: combinedLayers,
      });

      // Initialize layer visibility state with explicit type
      const initialLayerVisibility: Record<string, boolean> = combinedLayers.reduce((acc, layer) => {
        acc[layer.name] = layer.visible; // Use the 'visible' property to set initial visibility
        return acc;
      }, {} as Record<string, boolean>);

      setLayerVisibility(initialLayerVisibility);
      setData(combinedData);

      // Fit map to bounds of the combined data
      const geojsonObject = L.geoJSON(combinedData as GeoJsonObject);
      try {
        map.fitBounds(geojsonObject.getBounds());
        setLoaded(true)
      } catch (e) {
        map.fitWorld();
        console.log('bounds error');
        console.log(e);
      }
    };
    getData().catch(console.error);
  }, [drawingUrl, asBuiltUrl, map]);

  useEffect(() => {
    if (geoJsonLayer.current && data) {
      geoJsonLayer.current.clearLayers().addData({
        type: 'FeatureCollection',
        features: data.features.filter(filterLayers),
      } as GeoJsonObject);
    }
  }, [layerVisibility, data]);

  const layerColors = data?.layers.reduce((acc, element) => {
    acc[element.name] = element.color;
    if ( element.color === '#45230A') {
      acc[element.name] = '#a17d62'
    }
    return acc;
  }, {} as Record<string, string>);

  const pointToLayer = (feature: any, latlng: any) => {
    const layer = feature.properties.layer;
    let color = feature.properties.color ? feature.properties.color : (layerColors ? layerColors[layer] : '#000');
    if (color === '#45230A') {
      color = '#a17d62'
    }

    return L.circleMarker(latlng, {
      radius: 2,
      fillColor: color,
      color: '#fff',
      weight: 1,
      opacity: 1,
      fillOpacity: 1,
    });
  };

  const geoJsonStyle = (feature: any) => {
    const layerName = feature.properties.layer;
    let color = feature.properties.color ? feature.properties.color : (layerColors ? layerColors[layerName] : '#000');
    if (color === '#45230A') {
      color = '#a17d62'
    }
    const layer = data?.layers.find(l => l.name === feature.properties.layer);
    const weight = layer?.category && layer.category.includes('As-Built') ? 3.5 : 2;
    return {
      weight: weight,
      opacity: 1,
      color: color,
    };
  };

  const onEachFeature = (feature: any, layer: any) => {
    if (feature.geometry && feature.geometry.coordinates) {
      const layerName = feature.properties.layer;
      let coordinate: string = '';

      if (feature.geometry.type === 'Point') {
        // Coordinates for Point are flat [x, y]
        coordinate = feature.properties.originalCoordinates
          .map((num: number) => num.toFixed(2))
          .join(', ');
      }
      if (coordinate) {
        coordinate = ` \n(${coordinate})`
      }

      layer.bindPopup(`${layerName}${coordinate}`);
    }
  };

  const filterLayers = (feature: any) => {
    const layer = feature.properties.layer;
    const removed = feature.properties.removed
    return !removed && (layerVisibility[layer] || false);
  };

  const handleLayerVisibilityChange = (layerName: string) => {
    setLayerVisibility((prevVisibility) => ({
      ...prevVisibility,
      [layerName]: !prevVisibility[layerName],
    }));
  };

  const handleMouseEnter = () => {
    map.scrollWheelZoom.disable()
    map.doubleClickZoom.disable()
    map.dragging.disable()
  };

  const handleMouseLeave = () => {
    map.scrollWheelZoom.enable()
    map.doubleClickZoom.enable()
    map.dragging.enable()
  };

  const filterRemovedLayers = (layer: any) => {
    const name = layer.name
    const layerFeatures = data?.features.filter((f: any) => f.properties.layer === name).length
    return layerFeatures && layerFeatures > 0
  }

  return (
    <>
      {data && (
        <div
          className="checkbox-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Layers:
          {(function(): React.ReactNode {
            let prevCategory = 'Drawing';
            return data.layers.filter(filterRemovedLayers).map((layer) => {
              let categoryBreak = null;
              if (layer.category && layer.category != prevCategory) {
                prevCategory = layer.category;
                categoryBreak = (
                  <div style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.2em' }}>
                    <br />
                    {layer.category}
                  </div>
                );
              }
              return (
                <React.Fragment key={layer.name}>
                  {categoryBreak}
                  <label style={{
                    color: layerColors ? layerColors[layer.name] : '#000',
                    fontSize: '1.2em',
                    textShadow: '0.5px 0.5px 0 #555'
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={layerVisibility[layer.name]}
                        onChange={() => handleLayerVisibilityChange(layer.name)}
                        style={{ accentColor: layerColors ? layerColors[layer.name] : '#000', marginRight: '5px' }}
                      />
                      {layer.name}
                    </div>
                  </label>
                </React.Fragment>
              );
            });
          })()}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        {data && (
          <GeoJSON
            ref={geoJsonLayer}
            data={{
              type: 'FeatureCollection',
              features: data.features.filter(filterLayers),
            } as GeoJsonObject}
            style={geoJsonStyle}
            pointToLayer={pointToLayer}
            onEachFeature={onEachFeature}
          />

        )}
        {!loaded && (
          <Loader/>
        )}
      </div>
    </>
  );
};

export const Map = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const location = useLocation();
  const [file, setFile] = React.useState();

  const fileId = location.state.id

  useEffect(() => {
    async function fetchFile() {
      try {
        const res = await getFile(fileId)
        if (res) {
          setFile(res.data)
        }
      } catch (e) {
        console.error(e)
      }
    }
    fetchFile().catch(console.error)
  }, [fileId])

  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        title:
          AuthUser.getAuthUser().role === 'admin' ? 'Projects' : 'Dashboard',
        href:
          AuthUser.getAuthUser().role === 'admin'
            ? '/projects-list'
            : '/dashboard',
      },
      {
        title: searchParams.get('projectName'),
        href:
          '/view-project-details/' +
          id +
          '?projectName=' +
          searchParams.get('projectName'),
      },
    ];
    const currentBreadcrumb = searchParams.get('breadcrumb');

    if (currentBreadcrumb) {
      const currentBreadcrumbPath = currentBreadcrumb
        .trim()
        .replace(/^>/, '')
        .split('>');

      let setNewBreadcrumb = '';
      currentBreadcrumbPath.map(function (item, i) {
        setNewBreadcrumb = setNewBreadcrumb + '>' + item;
        const currentCrumb = item.split('-path-');

        const currentProjectName = searchParams.get('projectName');
        if (i + 1 !== currentBreadcrumbPath.length) {
          breadcrumb.push({
            title: currentCrumb[0],
            href:
              '/view-project-details/' +
              id +
              '?projectName=' +
              currentProjectName +
              '&directory=' +
              currentCrumb[1] +
              '&breadcrumb=' +
              setNewBreadcrumb,
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          breadcrumb.push({
            title: currentCrumb[0],
            href: '/view-project-details/' + id + location.search,
          });
        }
      });
    }

    return breadcrumb;
  };

  const DownloadButton = ({file}: any) => {

    const [downloading, setDownloading] = React.useState(false);

    const handleDownload = async (type: string) => {
      setDownloading(true)
      try {
        const response = await exportAsBuilt(file.id, type);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let filename = `${file.name}_asBuilt.${type}`;
        if (contentDisposition) {
          const matches = /filename="([^"]+)"/.exec(contentDisposition);
          if (matches && matches[1]) {
            filename = matches[1];
          }
        }

        link.setAttribute('download', filename); // Specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        toast.error('Download failed')
        console.error('Error downloading the file:', e);
      } finally {
        setDownloading(false)
      }
    };

    return (
      <div>
        <Button className="common-btn button-with-margin" onClick={() => handleDownload("pdf")}>
          Download PDF
        </Button>
        <Button className="common-btn" onClick={() => handleDownload("dwg")}>
          Download DWG
        </Button>
        <h1 />
        {downloading && <Loader/>}
      </div>
    );
  };

  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar
            currentVal={
              AuthUser.getAuthUser().role === 'user'
                ? 'dashboard'
                : 'projects-list'
            }
          />
          {file ? (
            <Content>
              <Breadcrumb separator=">" items={getBreadcrumb()} />
              <DownloadButton file={file} />
              <MapContainer
                style={{ width: '100%', height: '100vh', backgroundColor: 'black' }}
                zoom={10}
                id="mapId"
                scrollWheelZoom={true}
                fadeAnimation={true}
                markerZoomAnimation={true}
              >
                <MyData file={file} />
              </MapContainer>
            </Content>
          ) : (
            <Loader/>
          )}
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
        </Layout>
      </Layout>
    </>
  );
};
