import React from 'react';
import { Form, Input } from 'antd';

const FormItemInputComponent = ({
  label,
  name,
  className,
  placeholder,
  inputType,
  suffix,
  onChangeFunction,
  initialValues,
  rules,
  disabled,
  min,
  step,
}: any) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className={className}
      rules={rules}
      initialValue={initialValues}
    >
      <Input
        name={name}
        onChange={onChangeFunction}
        type={inputType}
        placeholder={placeholder}
        suffix={suffix}
        disabled={disabled}
        min={min}
        step={step}
        // defaultValue={initialValues}
      />
    </Form.Item>
  );
};

export default FormItemInputComponent;
