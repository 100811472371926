import React, { useState } from 'react';
import { Select, Input } from 'antd';
import { Link } from 'react-router-dom';
import images from '../../Assets/Images';
import AuthUser from '../../Utils/user';

const { Search } = Input;

const PageContentHeader = ({
  headerTitle,
  showSearchBar,
  showStatus,
  MapSelect,
  onSearch,
  showButton,
}: any) => {
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);

  const searchBoxOpen = () => {
    setSearchBoxVisible(!searchBoxVisible);
  };
  const onChange = (value: string) => {
    console.warn(value);
  };
  return (
    <div
      className={`page-content-header ${
        showSearchBar ? 'searchbox-header' : ''
      } ${MapSelect ? 'searchbox-header map-page-header' : ''} `}
    >
      <h3>{headerTitle}</h3>
      {showSearchBar || MapSelect || showButton ? (
        <div
          className={`pagecontent-header-rightbox ${
            showStatus ? 'employer-header-content' : ''
          }`}
        >
          {MapSelect ? (
            <Select
              showSearch={false}
              className="vendor-select"
              placeholder="Choose vendor"
              style={{ width: 280 }}
              optionFilterProp="children"
              onChange={onChange}
              popupClassName="status-popup"
              options={[
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'blocked',
                  label: 'Blocked',
                },
                {
                  value: 'pending',
                  label: 'Pending',
                },
              ]}
            />
          ) : null}
          {showStatus ? (
            <Select
              showSearch={false}
              placeholder="User Status"
              optionFilterProp="children"
              onChange={onChange}
              popupClassName="status-popup"
              options={[
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'blocked',
                  label: 'Blocked',
                },
                {
                  value: 'pending',
                  label: 'Pending',
                },
              ]}
            />
          ) : null}
          {showStatus ? (
            <Select
              showSearch={false}
              className="status-select"
              placeholder="Status"
              optionFilterProp="children"
              onChange={onChange}
              popupClassName="status-popup"
              options={[
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'blocked',
                  label: 'Blocked',
                },
                {
                  value: 'pending',
                  label: 'Pending',
                },
              ]}
            />
          ) : null}
          {showSearchBar ? (
            <div className="search-box">
              <Search
                className={`searchinput ${
                  searchBoxVisible ? 'searchboxopen' : 'searchboxclose'
                }`}
                placeholder="input search text"
                allowClear
                enterButton={false}
                onSearch={onSearch}
              />
              <img src={images.Search} alt="search" onClick={searchBoxOpen} />
            </div>
          ) : null}

          {headerTitle === 'List of Users' && (
            <Link to="/add-user" className="common-btn">
              Add User
            </Link>
          )}

          {headerTitle === 'List of Organization' && (
            <Link to="/add-organization" className="common-btn">
              Add Organization
            </Link>
          )}
          {headerTitle === 'List of Projects' &&
            AuthUser.getAuthUser().role !== 'user' && (
              <Link to="/add-project" className="common-btn">
                Add Project
              </Link>
            )}
        </div>
      ) : null}
    </div>
  );
};

export default PageContentHeader;
