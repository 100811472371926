import { apiRoutes } from '../../apiRoutes';
import { get, post, put, remove, upload, downloadFile } from '../../apiServices';

interface createDirectoryInterface {
  projectId: string | undefined;
  directory: {
    name: string | null;
    parentId: string | null;
  };
}

interface uploadDrawingInterface {
  projectId: string | undefined;
  drawing: {
    name: string | null;
    parentId: string | null;
    simplifyThreshold: number | null;
  };
  file: any;
}

interface updateFileInterface {
  name: string | null;
}

export const createDirectory = async (formData: createDirectoryInterface) => {
  try {
    return await post(`${apiRoutes.directory.create}`, formData);
  } catch (error: any) {
    return error.response;
  }
};
export const uploadDrawing = async (formData: any) => {
  try {
    return await upload(`${apiRoutes.directory.uploadFile}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const getDirectoryList = async (directoryId: string | undefined) => {
  try {
    return await get(`${apiRoutes.directory.list}${directoryId}`);
  } catch (error: any) {
    return error.response;
  }
};

export const getFile = async (id: string | undefined) => {
  try {
    return await get(`${apiRoutes.directory.get}${id}`)
  } catch (error: any) {
    return error.response
  }
}

export const updateFile = async (
    id: string | undefined,
    formData: updateFileInterface,
) => {
  try {
    return await put(`${apiRoutes.directory.update}${id}`, formData);
  } catch (error: any) {
    return error.response
  }
};

export const deleteDirectoryOrFile = async (id: string | undefined) => {
  try {
    return await remove(`${apiRoutes.directory.deleteFile}${id}`);
  } catch (error: any) {
    return error.response;
  }
};

export const exportAsBuilt = async (id: string | undefined, type: string | undefined) => {
  try {
    return await downloadFile(`${apiRoutes.directory.exportAsBuilt}/${id}/as-built/export?type=${type}`);
  } catch (error: any) {
    return error.response;
  }
};
