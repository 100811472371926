import React, { useEffect, useState } from 'react';
import { Layout, Button, Table, Modal, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import type { ColumnsType } from 'antd/es/table';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import { useNavigate } from 'react-router-dom';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';
import {
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';

//API
import { resendInvitation, deleteNewUser, deleteUser, getPendingUsers, getUsers } from '../../../Network/Core/Users/UserServices';

//Helper
import AuthUser from '../../../Utils/user';

//CSS
import './UserList.css';

const { Content } = Layout;
const { confirm } = Modal;

export const UserList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    setUser(AuthUser.getAuthUser());
  }, []);

  useEffect(() => {
    (async () => {
      await getUserList();
    })()
  }, []);

  const navigate = useNavigate();

  const getUserList = async (search: string = '') => {
    try {
      const data = (await getUsers(search)).data;
      const pendingData = (await getPendingUsers(search)).data;
      setUsers(data.users);
      setPendingUsers(pendingData.users);
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
      setIsLoading(false);
    }
  };

  const onEditClick = (email: string) => {
    navigate('/edit-user/' + email);
  };

  const onSearch = async (input: string) => {
    await getUserList(input);
  };

  const removeUser = (email: string) => {
    try {
      (async () => {
        await deleteUser(email);
        await getUserList();
        toast.success('User removed successfully');
      })();
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const removeNewUser = (email: string) => {
    try {
      (async () => {
        await deleteNewUser(email);
        await getUserList();
        toast.success('User removed successfully');
      })();
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const resendInvitationClicked = (email: string) => {
    try {
      (async () => {
        await resendInvitation(email);
        toast.success('User removed successfully');
      })();
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const showDeleteConfirm = (name: string, email: string) => {
    confirm({
      title: 'Are you sure you want to delete ' + name + '?',
      icon: <ExclamationCircleFilled />,
      width: 592,
      content: '',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      closable: true,
      onOk() {
        removeUser(email);
      },
      onCancel() {
        console.warn('Cancel');
      },
    });
  };

  const showDeleteNewUserConfirm = (email: string) => {
    confirm({
      title: 'Are you sure you want to delete ' + email + '?',
      icon: <ExclamationCircleFilled />,
      width: 592,
      content: '',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      closable: true,
      onOk() {
        removeNewUser(email);
      },
      onCancel() {
        console.warn('Cancel');
      },
    });
  };

  const showResendInvitationConfirm = (email: string) => {
    confirm({
      title: 'Are you sure you want to resend invitation?',
      icon: <ExclamationCircleFilled />,
      width: 592,
      content: '',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      closable: true,
      onOk() {
        resendInvitationClicked(email);
      },
      onCancel() {
        console.warn('Cancel');
      }
    });
  };

  interface DataType {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    organization: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    /*
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      sorter: false,
      className: 'a-tag',
      onCell: (record) => {
        return {
          onClick: () => {
            onEditClick(record.email);
          },
        };
      },
    },
    */
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          <div className="action-box">
            <Tooltip title="Edit User">
              <Button
                className="action-btn"
                onClick={() => onEditClick(record.email)}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            {record.email !== user.email && (
            <Tooltip title="Delete User">
              <Button
                className="action-btn"
                onClick={() =>
                  showDeleteConfirm(
                    record.firstName + ' ' + record.lastName,
                    record.email,
                  )
                }
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
              )}
          </div>
        </>
      ),
    },
  ];

  const pendingColumns: ColumnsType<DataType> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    /*
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      sorter: false,
      className: 'a-tag',
      onCell: (record) => {
        return {
          onClick: () => {
            onEditClick(record.email);
          },
        };
      },
    },
    */

    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          <div className="action-box">
            <Tooltip title="Resend Invitation">
              <Button
                className="action-btn"
                onClick={() => showResendInvitationConfirm(record.email)}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>
            {record.email !== user.email && (
              <Tooltip title="Delete Token">
                <Button
                  className="action-btn"
                  onClick={() =>
                    showDeleteNewUserConfirm(record.email)
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
        </>
      ),
    },
]

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="users-list" />
          <Content>
            <PageContentHeader
              headerTitle="List of Users"
              showSearchBar={false}
              showButton={true}
              showStatus={false}
              MapSelect={false}
              onSearch={onSearch}
            />

            <div className="list-table">
              <Table
                columns={columns}
                tableLayout="auto"
                dataSource={users}
                rowKey={record => record.email}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>

            {pendingUsers.length > 0 && (
              <>
                <br />

                <PageContentHeader
                  headerTitle="Pending Users"
                  showSearchBar={false}
                  showButton={true}
                  showStatus={false}
                  MapSelect={false}
                  onSearch={onSearch}
                />

                <div className="list-table">
                  <Table
                    columns={pendingColumns}
                    tableLayout="auto"
                    dataSource={pendingUsers}
                    rowKey={record => record.email}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </>
            )}
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
        </Layout>
      </Layout>
    </>
  );
};
