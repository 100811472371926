import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';

//Components
import {Sidebar} from '../../../Components/Sidebar/Sidebar';
import {HeaderBar} from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';
import EditUserFormComponent from '../../../Components/CoreComponents/EditUserFormComponent';

//API
import {getUser} from '../../../Network/Core/Users/UserServices';

//CSS
import '../UserList/UserList.css';

const {Content} = Layout;

export const EditUser = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState();
    const {email} = useParams();

    useEffect(() => {
        void getUserDetail();
    }, []);

    const navigate = useNavigate();

    const getUserDetail = async () => {
        if (email) {
            try {
                const data = (await getUser(email)).data;
                setUser(data);
                setIsLoading(false);
            } catch (error: any) {
                toast.error('Something went wrong');
                setIsLoading(false);
                navigate('/users-list');
            }
        } else {
            toast.error('Something went wrong');
            navigate('/users-list');
        }
    };
    return (
        <>
            {isLoading && <Loader/>}
            <Layout className="layout_wrapper">
                <HeaderBar activePopupLink=""/>
                <Layout>
                    <Sidebar currentVal="users-list"/>
                    <Content>
                        <PageContentHeader
                            headerTitle="Edit User"
                            showSearchBar={false}
                            showStatus={false}
                            MapSelect={false}
                        />
                        <div className="Main-content-box">
                            {user && (
                                <EditUserFormComponent FormTitle="edit-user" user={user}/>
                            )}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
