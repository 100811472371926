import { Layout } from 'antd';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import ProfileForm from '../../../Components/CoreComponents/ProfileForm';

//Helper
import withAuth from '../../../Utils/wrapper';

//CSS
import '../../OrganizationListPages/Organization.css';

const { Content } = Layout;

const validateMessages = {
  required: '${name} is required!',
  passwordsDontMatch: 'Passwords do not match!',
};

function Profile() {
  // const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="profile" />

        <Layout>
          <Sidebar currentVal="profile" />
          <Content>
            <PageContentHeader
              headerTitle="User Profile"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box change-pwd-contbox">
              <ProfileForm validateMessages={validateMessages} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(Profile);
