import React from 'react';
import { Layout } from 'antd';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import AddUserFormComponent from '../../../Components/CoreComponents/AddUserFormComponent';

import '../UserList/UserList.css';

const { Content } = Layout;

export const AddUser = () => {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="users-list" />
          <Content>
            <PageContentHeader
              headerTitle="Add User"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <AddUserFormComponent FormTitle="add-user" />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
