import React from 'react';
import '../../Assets/Css/Loader.css';

const Loader = () => {
  return (
    <div className="o-page-loader">
      <div className="o-page-loader--content">
        <div className="o-page-loader--spinner" />
        <div className="o-page-loader--message">
          <span>Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
