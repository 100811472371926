import React, { useState } from 'react';
import { Card, Button, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

//Components
import FormItemInputComponent from './FormItemInputComponent';

//Static Images
import images from '../../Assets/Images';

//API
import { forgotPassword } from '../../Network/Core/Auth/AuthServices';

interface FormValues {
  email: string;
}
const ForgotCardComponent = ({ validateMessages, backToLoginLink }: any) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const [formData, setFormData] = useState<FormValues>({
    email: '',
  });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.email !== '') {
      try {
        setIsFormValid(false);
        await forgotPassword(formData);
        toast.success(
          'Kindly review your email as a reset password link has been dispatched.'
        );
        navigate('/');

        setIsFormValid(true);
      } catch (error: any) {
        setIsFormValid(true);
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <Card className="Auth_card forgot-card" style={{ width: '469px' }}>
      <div className="forgot-icon">
        <img src={images.Key} alt="key" className="key" />
      </div>
      <h3>Forgot Password?</h3>
      <p>No worries! We’ll help you change</p>
      <Form
        name="forgot-password-form"
        onSubmitCapture={handleSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        {/* <Form.Item name="email" rules={[{ type: 'email', required: true }]}>
                <Input
                    placeholder="Enter registered e-mail"
                    suffix={
                        <MailOutlined />
                    }
                    />
            </Form.Item> */}
        <FormItemInputComponent
          label={null}
          name="email"
          onChangeFunction={handleFormChange}
          className=""
          placeholder="Enter registered e-mail"
          inputType="text"
          suffix={<MailOutlined />}
          rules={[
            { type: 'email', message: 'Please add proper Email' },
            { required: true, message: 'Email is required!' },
          ]}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!isFormValid}>
            send link
          </Button>
        </Form.Item>
        <div className="backtologin">
          <Link to={backToLoginLink}>
            <img src={images.Arrow} alt="arrow" className="arrow" /> Back to
            login
          </Link>
        </div>
      </Form>
    </Card>
  );
};

export default ForgotCardComponent;
