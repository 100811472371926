import React, { useEffect, useState } from 'react';
import { Layout, Popover, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//Images
import images from '../../Assets/Images';

//Helper
import AuthUser from '../../Utils/user';

//API
import { logOut, login } from '../../Network/Core/Auth/AuthServices';

const { Header } = Layout;

export const HeaderBar: any = ({ activePopupLink }: any) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    setUser(AuthUser.getAuthUser());
  }, []);

  const handleClick = async () => {
    // Perform logout action (e.g. clear local storage or make API call)
    await logOut()
    toast.success('User logout successfully');
    localStorage.clear();
    navigate('/');
  };

  const backToAdmin = async () => {
    // Back to admin login
    try {
      const data = (await login({
        email: AuthUser.getAuthUser().email,
        password: AuthUser.getPassword(),
        overrideOrganization: undefined
      })).data;
      const jwt_token = 'Bearer ' + data.tokens.accessToken.token;

      localStorage.setItem('token', jwt_token);
      localStorage.setItem(
        'expirationDate',
        data.tokens.accessToken.expirationDate
      );
      localStorage.setItem('refreshToken', data.tokens.refreshToken.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      localStorage.removeItem('is_owner');
      toast.success('Back to admin successfully!');
      navigate(AuthUser.getRedirectPath());
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const content = (
    <div className="user-popover">
      {AuthUser.isOwner() && (
        <p>
          <a onClick={backToAdmin}>Back to Admin</a>
        </p>
      )}
      {!AuthUser.isOwner() && (
        <>
          <p>
            <Link
              to="/profile"
              className={activePopupLink === 'profile' ? 'active-link' : ''}
            >
              Profile
            </Link>
          </p>

          <p>
            <Link
              to="/change-password"
              className={
                activePopupLink === 'change-password' ? 'active-link' : ''
              }
            >
              Change Password
            </Link>
          </p>
        </>
      )}
      <p>
        <Link to="/" onClick={handleClick}>
          Logout
        </Link>
      </p>
    </div>
  );
  return (
    <Header className="header">
      <Link to={AuthUser.getRedirectPath()}>
        <img src={images.AuthLogo} alt="logo" />
      </Link>
      <div className="Header-top-content">
        <Popover content={content} trigger="click">
          <Button>
            {user && user.firstName && (
              <span>{user.firstName + ' ' + user.lastName}</span>
            )}
          </Button>
        </Popover>
        <p>
          {user && user.role && (
            <span
              className="first-latter-caps"
              style={{ textTransform: 'capitalize' }}
            >
              {user.role}
            </span>
          )}
        </p>
      </div>
    </Header>
  );
};
