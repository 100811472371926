import React, { useEffect, useState } from 'react';
import {
  Layout,
  Button,
  Table,
  Modal,
  Tooltip,
  Breadcrumb,
  Form,
  Upload,
  Drawer
} from 'antd';
import { toast } from 'react-toastify';
import type { ColumnsType } from 'antd/es/table';
import type { UploadFile, UploadProps } from 'antd';
import {
  UploadOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileImageOutlined,
  FolderAddOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import Loader from '../../../Components/CoreComponents/Loader';
import FormItemInputComponent from '../../../Components/CoreComponents/FormItemInputComponent';
import { Map } from '../../../Components/CoreComponents/MapComponent';

//API
import {
  createDirectory,
  deleteDirectoryOrFile,
  getDirectoryList,
  uploadDrawing,
} from '../../../Network/Core/Directory/DirectoryServices';

//CSS
import './ProjectDetail.css';
import AuthUser from '../../../Utils/user';
import { getProjectList } from '../../../Network/Core/Project/ProjectServices';

const { Content } = Layout;

interface FormValues {
  folderName: string;
}
interface UploadFormValues {
  fileName: string;
  simplifyThreshold: number;
  file: any;
}

export const ProjectDetailView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formUploading, setFormUploading] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [folders, setFolders] = useState([]);
  const [filename, setFilename] = useState('')
  const [inputFilename, setInputFilename] = useState('')
  const [form] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [isVisible, setVisible] = useState(false);
  const [selectedDrawing] = useState<DataType>();

  const navigate = useNavigate();
  const { Dragger } = Upload;

  const [formData, setFormData] = useState<FormValues>({
    folderName: '',
  });

  const [uploadFormData, setUploadFormData] = useState<UploadFormValues>({
    fileName: '',
    simplifyThreshold: 0.001,
    file: '',
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const uploadValues = Form.useWatch([], uploadForm);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  React.useEffect(() => {
    uploadForm.validateFields({ validateOnly: true }).then(
      () => {
        setUploading(true);
      },
      () => {
        setUploading(false);
      },
    );
  }, [uploadValues]);

  const { id } = useParams();
  const { confirm } = Modal;
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    setFormUploading(true);
    const customFormData = new FormData();
    fileList.forEach((file) => {
      customFormData.append('file', file as RcFile);
    });
    if (uploadFormData.fileName != '' && !uploadFormData.simplifyThreshold) {
      setUploading(true);
    }

    const directoryID = searchParams.get('directory');
    let directoryId = '';
    if (directoryID && directoryId === '') {
      directoryId = directoryID;
    }
    const drawing =
      '{"name": "' +
      uploadFormData.fileName +
      '", "parentId": "' +
      directoryId +
      '", "simplifyThreshold":' +
      uploadFormData.simplifyThreshold +
      '}';

    let projectId = '';

    if (typeof id === 'string' && id !== '') {
      projectId = id;
    }

    customFormData.append('projectId', projectId); //append the values with key, value pair
    customFormData.append('drawing', drawing);
    toast.warning('File is uploading');
    setShowUploadModal(false);
    await uploadDrawing(customFormData);
    setFileList([]);
    setUploadFormData({
      fileName: '',
      file: '',
      simplifyThreshold: 0.001
    });
    uploadForm.resetFields();
    uploadForm.setFieldsValue({
      simplifyThreshold: 0.001
    });

    setShowUploadModal(false);
    setIsLoading(false);
    setUploading(false);
    if (directoryId) {
      await getDirectoryLists(directoryId);
    } else {
      await getProjectsList(id);
    }
    toast.dismiss();
    toast.warning('File is uploaded for processing...');
    toast.warning('Please refresh to monitor progress');
    setFormUploading(false);
  };

  useEffect(() => {
    const directoryID = searchParams.get('directory');
    if (directoryID) {
      void getDirectoryLists(directoryID);
    } else {
      void getProjectsList(id);
    }
  }, [searchParams.get('directory'), showFolderModal]);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleUploadFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUploadFormData({
      ...uploadFormData,
      [event.target.name]: event.target.value,
    });
  };

  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        title:
          AuthUser.getAuthUser().role === 'admin' ? 'Projects' : 'Dashboard',
        href:
          AuthUser.getAuthUser().role === 'admin'
            ? '/projects-list'
            : '/dashboard',
      },
      {
        title: searchParams.get('projectName'),
        href:
          '/view-project-details/' +
          id +
          '?projectName=' +
          searchParams.get('projectName'),
      },
    ];
    const currentBreadcrumb = searchParams.get('breadcrumb');

    if (currentBreadcrumb) {
      const currentBreadcrumbPath = currentBreadcrumb
        .trim()
        .replace(/^>/, '')
        .split('>');

      let setNewBreadcrumb = '';
      currentBreadcrumbPath.map(function (item, i) {
        setNewBreadcrumb = setNewBreadcrumb + '>' + item;
        const currentCrumb = item.split('-path-');

        const currentProjectName = searchParams.get('projectName');
        if (i + 1 !== currentBreadcrumbPath.length) {
          breadcrumb.push({
            title: currentCrumb[0],
            href:
              '/view-project-details/' +
              id +
              '?projectName=' +
              currentProjectName +
              '&directory=' +
              currentCrumb[1] +
              '&breadcrumb=' +
              setNewBreadcrumb,
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          breadcrumb.push({
            title: currentCrumb[0],
          });
        }
      });
    }

    return breadcrumb;
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,

    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);

      setUploadFormData({
        ...uploadFormData,
        ['file']: null,
      });
      uploadForm.setFieldsValue({
        file: '',
      });
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      const fileName = file.name.split('.').slice(0, -1).join('.').substring(0, 50);
      const fileSize = file.size

      if (fileSize > 1024*1024*20) {
        uploadForm.setFields([
          {
            name: 'file',
            errors: ['File must be smaller than 20MB']
          }
        ]);
        return Upload.LIST_IGNORE; // Prevent the file from being added to the list
      }

      const allowedExtensions = ['.dwg'];
      if (!allowedExtensions.includes(fileExtension)) {
        uploadForm.setFields([
          {
            name: 'file',
            errors: ['File type is not supported. Please upload a valid DWG file.'],
          },
        ]);
        return Upload.LIST_IGNORE;
      }

      setFileList([file]);
      setFilename(fileName);
      setUploadFormData({
        ...uploadFormData,
        ['file']: file,
        ['fileName']: fileName,
      });

      uploadForm.setFieldsValue({
        fileName: fileName,
        file: file,
      });

      void uploadForm.validateFields();

      setUploading(true);

      return false; // Prevent automatic upload
    },
    fileList,
  };

  const showModal = () => {
    form.resetFields();
    form.setFieldsValue({
      folderName: '',
    });
    setShowFolderModal(true);
  };

  const handleOk = () => {
    setShowFolderModal(false);
  };

  const handleCancel = () => {
    setShowFolderModal(false);
  };

  const onshowUploadModal = () => {
    uploadForm.resetFields();
    uploadForm.setFieldsValue({
      simplifyThreshold: 0.001,
      file: '',
      fileName: '',
    });
    setShowUploadModal(true);
  };

  const handleUploadOk = () => {
    setShowUploadModal(false);
  };

  const onFinish = () => {
    const directoryID = searchParams.get('directory');
    if (directoryID) {
      void createFolder(formData.folderName, directoryID);
      if (directoryID) {
        void getDirectoryLists(directoryID);
      } else {
        void getProjectsList(id);
      }
    } else {
      void createFolder(formData.folderName);
    }
    setShowFolderModal(false);
  };
  const formatDate = (string: string) => {
    return new Date(string).toLocaleDateString([], {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleUploadCancel = () => {
    uploadForm.resetFields();
    setUploadFormData({
      fileName: '',
      file: '',
      simplifyThreshold: 0.001,
    });
    setFileList([]);
    setShowUploadModal(false);
  };

  const viewDrawing = (record: DataType) => {
    const data = {id: record.id}
    navigate('/view-drawing/' + id + getCurrentParams(), {
      state: data,
    });
  };

  const onEditClick = (file: DataType) => {
    navigate(`/edit-file/${file.id}`, {
      state: file
    });
  };

  const getCurrentParams = () => {
    return location.search;
  };

  const viewDirectory = (directoryId: string, directoryName: string) => {
    setFormUploading(true);
    const currentBreadcrumb = searchParams.get('breadcrumb')
      ? searchParams.get('breadcrumb')
      : '';

    navigate(
      '/view-project-details/' +
        id +
        '?projectName=' +
        searchParams.get('projectName') +
        '&directory=' +
        directoryId +
        '&breadcrumb=' +
        currentBreadcrumb +
        '>' +
        directoryName +
        '-path-' +
        directoryId,
    );
    void getDirectoryLists(directoryId);
    setFormUploading(false);
  };
  interface DataType {
    createdAt: string;
    createdBy: string;
    id: string;
    status: string;
    name: string;
    projectId: string;
    parentId: string;
    type: string;
    simplifiedGeojson: string;
    originalDWG: string;
    asBuilt: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'a-tag',
      sorter: (a, b) => a.name.localeCompare(b.name),
      onCell: (record) => {
        let isDragging = false;

        return {
          onMouseDown: (e) => {
            isDragging = false; // Reset the dragging flag on mouse down
          },
          onMouseMove: (e) => {
            // Check if the mouse is being dragged
            if (e.buttons === 1) { // Left mouse button is pressed
              isDragging = true;
            }
          },
          onMouseUp: (e) => {
            // Execute the click action only if not dragging
            if (!isDragging) {
              if (!record.status || record.status === 'Completed') {
                if (record.type === 'directory') {
                  viewDirectory(record.id, record.name);
                } else {
                  viewDrawing(record);
                }
              }
            }
          },
        };
      },
      render: (_, record) => (
        <>
          {record.type === 'directory' ? (
            <>
              {/* <span 
               className="a-tag"
               onClick={() => viewDirectory(record.id, record.name)}
             >
            */}
              <FolderAddOutlined  style={{
                opacity: record.status !== 'Completed' ? 0.65 : 1.0,
              }} key={record.id}/>
              {' ' + record.name}
              {/* // </span> */}
            </>
          ) : (
            <>
              {/* <Link
                className="a-tag"
                to={'/view-drawing/' + id + getCurrentParams()}
                state={record}
              > */}
              {/* <span className="a-tag" onClick={() => viewDrawing(record)}> */}
              <FileImageOutlined  style={{ opacity: record.status !== 'Completed' ? 0.65 : 1.0 }} key={record.id}/> {' ' + record.name}
              {/* </span> */}
              {/* </Link> */}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'a-tag',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      onCell: (record) => {
        return {
          onClick: () => {
            if (!record.status || record.status === 'Completed') {
              if (record.type === 'directory') {
                viewDirectory(record.id, record.name);
              } else {
                viewDrawing(record);
              }
            }
          },
        };
      },
      render: (_, record) => (
        <>
          <span style={{ opacity: record.status !== 'Completed' ? 0.65 : 1.0 }}>{formatDate(record.createdAt)}</span>
        </>
      ),
    },
    {
      title: 'Uploaded By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      className: 'a-tag',
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      onCell: (record) => {
        return {
          onClick: () => {
            if (!record.status || record.status === 'Completed') {
              if (record.type === 'directory') {
                viewDirectory(record.id, record.name);
              } else {
                viewDrawing(record);
              }
            }
          },
        };
      },
      render: (_, record) => (
        <>
          <span style={{ opacity: record.status !== 'Completed' ? 0.65 : 1.0 }}>{record.createdBy}</span>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'a-tag',
      sorter: (a, b) => !a.status ? 1 : a.status.localeCompare(b.status),
      onCell: (record) => {
        return {
          onClick: () => {
            if (!record.status || record.status === 'Completed') {
              if (record.type === 'directory') {
                void viewDirectory(record.id, record.name);
              } else {
                viewDrawing(record);
              }
            }
          },
        };
      },
      render: (_, record) => (
        <>
          {record.status && (
            <span
              className={`status 
              ${record.status !== 'Completed' ? 'not-completed' : ''} 
              ${record.status.toLowerCase()}`}
            >
              {record.status}
            </span>
          )}
        </>
      )
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          {record.type === 'directory' && (
            <div className="action-box">
              <span style={{ width: '42px' }} />
              <Tooltip title="Edit Folder">
                <Button
                      className="action-btn"
                      onClick={() => onEditClick(record)}
                  >
                    <EditOutlined className="view-project-btn"/>
                  </Button>
                </Tooltip>
                <Tooltip title="Delete Directory">
                  <Button
                      className="action-btn"
                      onClick={() => showDeleteConfirm(record.name, record.id)}
                  >
                    <DeleteOutlined className="view-project-btn"/>
                  </Button>
                </Tooltip>
              </div>
          )}
          {record.type === 'drawing' && (
            <div className="action-box" key={'delete' + record.id}>
              <Tooltip title="Edit Drawing">
                <Button
                    className="action-btn"
                    onClick={() => onEditClick(record)}
                >
                  <EditOutlined className="view-project-btn"/>
                </Button>
              </Tooltip>
              <Tooltip title="Delete Drawing">
                <Button
                  className="action-btn"
                  onClick={() => showDeleteConfirm(record.name, record.id)}
                >
                  <DeleteOutlined className="view-project-btn" />
                </Button>
              </Tooltip>
            </div>
          )}
        </>
      ),
    },
  ];

  const getDirectoryLists = async (directoryId: string | undefined = '') => {
    setIsLoading(true);
    setFormUploading(true);
    try {
      const data = (await getDirectoryList(directoryId)).data;

      setFolders(data.files);
      setIsLoading(false);
      setFormUploading(false);
    } catch (error: any) {
      toast.error('Something went wrong');
      setIsLoading(false);
      setFormUploading(false);
    }
  };

  const getProjectsList = async (projectId: string | undefined = '') => {
    setIsLoading(true);
    setFormUploading(true);
    try {
      const data = (await getProjectList(projectId)).data;
      setFolders(data.files);
      setIsLoading(false);
      setFormUploading(false);

    } catch (error: any) {
      toast.error('Something went wrong');
      setIsLoading(false);
      setFormUploading(false);
    }
  };

  const showDeleteConfirm = (name: string, id: string) => {
    confirm({
      title: 'Are you sure you want to delete ' + name + '?',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      width: 592,
      centered: true,
      closable: true,
      onOk() {
        void deleteDirectory(id);
      },
      onCancel() {
        console.warn('Cancel');
      },
    });
  };

  const deleteDirectory = async (directoryId: string | undefined = '') => {
    setFormUploading(true);
    try {
      await deleteDirectoryOrFile(directoryId);
      toast.success('Record deleted successfully');
      const dirId = searchParams.get('directory');
      if (dirId) {
        await getDirectoryLists(dirId);
      } else {
        await getProjectsList(id);
      }

    } catch (error: any) {
      toast.error('Something went wrong');
      setIsLoading(false);
      setFormUploading(false);
      void getProjectsList(id);
    }
    setFormUploading(false);
  };

  const createFolder = async (
    folderName: string = '',
    directoryId: string = ''
  ) => {
    setFormUploading(true);
    try {
      await createDirectory({
        projectId: id,
        directory: {
          name: folderName,
          parentId: directoryId
        }
      });

      toast.success('Directory created successfully');

      setFormData({
        ...formData,
        ['folderName']: ''
      });
      form.resetFields();

      if (directoryId) {
        await getDirectoryLists(directoryId);
      } else {
        await getProjectsList(id);
      }

    } catch
      (error: any)
      {
        toast.error('Something went wrong');
      }
    setFormUploading(false);
  };

  const handleClick = () => {
    setInputFilename(filename)
    uploadForm.setFieldsValue({fileName: filename})
  };

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar
            currentVal={
              AuthUser.getAuthUser().role === 'user'
                ? 'dashboard'
                : 'projects-list'
            }
          />
          <Content>
            <Breadcrumb separator=">" items={getBreadcrumb()} />

            <div className="page-content-header">
              <h3>Project Detail</h3>
              <div className="pagecontent-header-rightbox">
                <Button
                  disabled={formUploading}
                  className="common-btn"
                  onClick={showModal}
                >
                  Create Folder
                </Button>
                <Button
                  className="common-btn"
                  onClick={onshowUploadModal}
                  disabled={formUploading}
                >
                  <UploadOutlined /> Upload File
                </Button>
              </div>
            </div>
            <div className="list-table">
              <Table
                columns={columns}
                rowKey="id"
                tableLayout="auto"
                dataSource={folders}
                pagination={false}
                // scroll={{ x: 'max-content' }}
              />
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}

          {/* create folder modal */}
          <Modal
            centered
            title="Create Folder"
            footer={null}
            open={showFolderModal}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form
              form={form}
              layout="vertical"
              className="common-form"
              name="create-folder-form"
              onSubmitCapture={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormItemInputComponent
                label={null}
                name="folderName"
                className=""
                onChangeFunction={handleFormChange}
                placeholder="Enter Folder Name"
                inputType="text"
                // initialValues={formData.folderName}
                // suffix={<MailOutlined />}
                rules={[
                  { required: true, message: 'Folder Name is required' },
                  {
                    max: 30,
                    message: 'Folder Name must be max 30 characters.',
                  },
                  {
                    pattern: new RegExp(
                      '^[A-Za-z0-9- _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
                    ),
                    message: 'Special character not allowed',
                  },
                ]}
              />
              <div className="form-btns">
                <Button className="self-btn" onClick={handleCancel}>
                  cancel
                </Button>
                <Button
                  className="common-btn"
                  htmlType="submit"
                  disabled={!isFormValid}
                >
                  Ok
                </Button>
              </div>
            </Form>
          </Modal>
          {/* create folder modal */}

          {/* Upload file modal */}
          <Modal
            centered
            title="Upload File"
            footer={null}
            open={showUploadModal}
            onOk={handleUploadOk}
            onCancel={handleUploadCancel}
          >
            <Form
              form={uploadForm}
              layout="vertical"
              preserve={false}
              className="common-form"
              name="upload-file-form"
              onSubmitCapture={handleUpload}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormItemInputComponent
                label={'File Name'}
                name="fileName"
                id="filename"
                className=""
                onChangeFunction={handleUploadFormChange}
                placeholder="Enter File Name"
                inputType="text"
                value={inputFilename}
                // initialValues={uploadFormData.fileName}
                // suffix={<MailOutlined />}
                rules={[
                  { required: true, message: 'File Name is required' },
                  {
                    max: 50,
                    message: 'File Name must be max 50 characters.'
                  },
                  {
                    pattern: new RegExp(
                      '^[A-Za-z0-9- _]*[A-Za-z0-9][A-Za-z0-9 _]*$'
                    ),
                    message: 'Special character not allowed'
                  }
                ]}
              />

              <div className="form-button">
                <button type="button" onClick={handleClick}>
                  Use name from file
                </button>
              </div>

              <FormItemInputComponent
                label={'Simplify Threshold'}
                name="simplifyThreshold"
                className=""
                onChangeFunction={handleUploadFormChange}
                placeholder="Simplify Threshold"
                inputType="number"
                initialValues={0.001}
                step="0.001"
                min="0"
                // suffix={<MailOutlined />}
                rules={[
                  { required: true, message: 'Simplify Threshold is required' },
                  () => ({
                    validator(_: any, passValue: any) {
                      if (passValue > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error(
                            'Simplify Threshold must be greater than 0.'
                          )
                        );
                      }
                    }
                  })
                ]}
              />


              <Form.Item
                name="file"
                rules={[
                  {
                    required: true,
                    message: 'Please select the drawing file'
                  }
                ]}
              >
                <Dragger accept=".dwg" {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>

              <div className="form-btns">
                <Button className="self-btn" onClick={handleUploadCancel}>
                  cancel
                </Button>
                <Button
                  className="common-btn"
                  htmlType="submit"
                  disabled={!uploading}
                >
                  Ok
                </Button>
              </div>
            </Form>
          </Modal>
          {/* Upload file modal */}

          <Drawer
            open={isVisible}
            onClose={() => setVisible(false)}
            width="100VW"
          >
            <Content>
              <Map selectedDrawing={selectedDrawing} />
            </Content>
          </Drawer>
        </Layout>
      </Layout>
    </>
  );
};
