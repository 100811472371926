import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';
import EditProjectFormComponent from '../../../Components/CoreComponents/EditProjectFormComponent';

//API
import { getProject } from '../../../Network/Core/Project/ProjectServices';

//helpers
import AuthUser from '../../../Utils/user';

//CSS
import '../ProjectList/ProjectList.css';

const { Content } = Layout;

export const EditProject = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      await getProjectDetail()
    })()
  }, []);

  const navigate = useNavigate();

  const getProjectDetail = async () => {
    if (id) {
      try {
        const data = (await getProject(id)).data;
        setProject(data);
        setIsLoading(false);
      } catch (error: any) {
        toast.error('Something went wrong');
        navigate('/projects-list');
      }
    } else {
      toast.error('Something went wrong');
      navigate('/projects-list');
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar
            currentVal={
              AuthUser.getAuthUser().role === 'user'
                ? 'dashboard'
                : 'projects-list'
            }
          />
          <Content>
            <PageContentHeader
              headerTitle="Edit Project"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              {project && (
                <EditProjectFormComponent
                  FormTitle="edit-project"
                  project={project}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
