import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './Pages/AuthModule/AdminLogin';
import { ForgotPassword } from './Pages/AuthModule/AdminForgotPassword';

import { AddOrganization } from './Pages/OrganizationListPages/AddOrganization/AddOrganization';
import { NewOrganization } from './Pages/OrganizationListPages/AddOrganization/NewOrganization';
import { AddUser } from './Pages/UserListPages/AddUser/AddUser';
import { EditOrganization } from './Pages/OrganizationListPages/EditOrganization/EditOrganization';
import ChangePassword from './Pages/OrganizationListPages/ChangePassword/ChangePassword';
import { OrganizationList } from './Pages/OrganizationListPages/OrganizationList/OrganizationList';
import { CreatePassword } from './Pages/AuthModule/CreatePassword';
import { ProjectList } from './Pages/Projects/ProjectList/ProjectList';
import { EditUser } from './Pages/UserListPages/EditUser/EditUser';
import { AddProject } from './Pages/Projects/AddProject/AddProject';
import { EditProject } from './Pages/Projects/EditProject/EditProject';

import { ErrorPage } from './Pages/ErrorPage';
import Profile from './Pages/AuthModule/Profile/index';
import { UserList } from './Pages/UserListPages/UserList/UserList';
import { PrivateRoute } from './PrivateRoutes';
import { ProjectDetailView } from './Pages/Projects/ProjectView/ProjectViewDetail';
import {EditFile} from "./Pages/Projects/EditFile/EditFile";
import { AuthRoutes } from './AuthRoutes';
import { Map } from './Pages/Projects/ProjectView/viewDrawing';

export const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback="">
          <Routes>
            <Route path="/" element={<AuthRoutes component={Login} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/profile"
              element={
                <PrivateRoute
                  component={Profile}
                  role={['admin', 'owner', 'user']}
                />
              }
            />
            <Route path="/new-user" element={<NewOrganization />} />
            <Route
              path="/add-user"
              element={
                <PrivateRoute component={AddUser} role={['admin', 'owner']} />
              }
            />
            <Route
              path="/edit-user/:email"
              element={
                <PrivateRoute component={EditUser} role={['admin', 'owner']} />
              }
            />
            <Route
              path="/edit-file/:id"
              element={
                <PrivateRoute component={EditFile} role={['admin', 'owner', 'user']} />
              }
            />
            <Route
              path="/organization-list"
              element={
                <PrivateRoute component={OrganizationList} role={['owner']} />
              }
            />
            <Route
              path="/users-list"
              element={<PrivateRoute component={UserList} role={['admin']} />}
            />
            <Route
              path="/projects-list"
              element={
                <PrivateRoute
                  component={ProjectList}
                  role={['admin', 'user']}
                />
              }
            />
            <Route
              path="/add-project"
              element={<PrivateRoute component={AddProject} role={['admin']} />}
            />
            <Route
              path="/edit-project/:id"
              element={
                <PrivateRoute component={EditProject} role={['admin']} />
              }
            />
            <Route
              path="/add-organization"
              element={
                <PrivateRoute component={AddOrganization} role={['owner']} />
              }
            />
            <Route
              path="/edit-organization"
              element={
                <PrivateRoute component={EditOrganization} role={['owner']} />
              }
            />
            <Route
              path="/change-password"
              element={
                <PrivateRoute
                  component={ChangePassword}
                  role={['admin', 'owner', 'user']}
                />
              }
            />
            <Route path="/new-password" element={<CreatePassword />} />
            <Route path="/error-page" element={<ErrorPage />} />

            <Route
              path="/dashboard"
              element={<PrivateRoute component={ProjectList} role={['user']} />}
            />
            <Route
              path="/view-project-details/:id"
              element={
                <PrivateRoute
                  component={ProjectDetailView}
                  role={['user', 'admin']}
                />
              }
            />
            <Route
              path="/view-drawing/:id"
              element={
                <PrivateRoute component={Map} role={['user', 'admin']} />
              }
            />
            {/*<Route path="/project-detail" element={<ProjectDetail />} />*/}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
