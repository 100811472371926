import { apiRoutes } from '../../apiRoutes';
import { get, post, put, remove, patch } from '../../apiServices';

interface inviteUserInterface {
  organization: string | null;
  email: string;
  assignedProjects: Array<string>;
}

interface updateUserInterface {
  firstName: string | null;
  lastName: string | null;
  email: string;
  assignedProjects: Array<string>;
}
export const inviteUser = async (formData: inviteUserInterface) => {
  try {
    return await post(`${apiRoutes.user.invite}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const updateUser = async (formData: updateUserInterface) => {
  try {
    return await put(`${apiRoutes.user.update}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const getUsers = async (search: string) => {
  try {
    return await get(`${apiRoutes.user.list}?organization=${search}`);
  } catch (error: any) {
    return error.response;
  }
};

export const getPendingUsers = async (search: string) => {
  try {
    return await get(`${apiRoutes.user.pending}?organization=${search}`);
  } catch (error: any) {
    return error.response;
  }
}

export const getUser = async (email: string | undefined) => {
  try {
    return await get(`${apiRoutes.user.get}/${email}`);
  } catch (error: any) {
    return error.response;
  }
};

export const deleteUser = async (email: string | undefined) => {
  try {
    return await remove(`${apiRoutes.user.get}/${email}`);
  } catch (error: any) {
    return error.response;
  }
};

export const deleteNewUser = async (email: string | undefined) => {
  try {
    console.log()
    return await remove(`${apiRoutes.user.delete}new-user-token/${email}`);
  } catch (error: any) {
    return error.response;
  }
};

export const resendInvitation = async (email: string | undefined) => {
  try {
    console.log()
    return await patch(`${apiRoutes.user.patch}resend-new-user-token`, {email: email});
  } catch (error: any) {
    return error.response;
  }
};