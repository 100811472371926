import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';
import { getUsers } from '../../Network/Core/Users/UserServices';

//API
import {
  getProjectUsers,
  updateProject,
} from '../../Network/Core/Project/ProjectServices';

//Images
import images from '../../Assets/Images';

interface FormValues {
  name: string | null;
  assignedUsers: Array<string>;
}

const EditProjectFormComponent = ({ project }: any) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormValues>({
    name: project.name,
    assignedUsers: [],
  });
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      await Promise.all([getUserList(), getProjectUserList()])
    })()

  }, []);

  const getUserList = async (search: string = '') => {
    try {
      const data = (await getUsers(search)).data;
      if (data['users'].length > 0) {
        const userOpt: any = [];
        data['users'].map((user: any) =>
          userOpt.push({
            label: user.firstName + ' ' + user.lastName,
            value: user.email
          })
        );
        setUsers(userOpt);
      }
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const getProjectUserList = async () => {
    try {
      const data = (await getProjectUsers(id)).data;
      if (data['users'].length > 0) {
        const userOpt: any = [];
        data['users'].map((email: any) =>
          userOpt.push({
            value: email
          })
        );
        setFormData({
          ...formData,
          ['assignedUsers']: data['users']
        });
        setProjectUsers(userOpt);
      }
      setFetched(true);
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const updateProjects = async () => {
    if (formData.name !== '') {
      try {
        setIsFormValid(false);
        await updateProject({
          id: id,
          updatedProject: {
            name: formData.name,
            assignedUsers: formData.assignedUsers
          }
        })
          toast.success('Project updated successfully');
        navigate(-1);
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.error);
          setIsFormValid(true);
        }
      }
    } else {
      toast.error('Fill Required Fields');
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (value: any) => {
    setFormData({
      ...formData,
      ['assignedUsers']: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateProjects();
  };

  const backToProjectList = () => {
    navigate('/projects-list');
  };

  return (
    <Form
      className="common-form"
      layout="vertical"
      name="edit-project-form"
      form={form}
      onSubmitCapture={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="Project Name"
            name="name"
            className="name"
            placeholder="Enter Project Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            onChangeFunction={handleFormChange}
            rules={[
              { required: true, message: 'Project Name is required' },
              {
                max: 30,
                message: 'Project Name must be max 30 characters.',
              },
            ]}
            initialValues={project.name}
          />
        </Col>

        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <Form.Item
            label="Assign User"
            name="select User"
            className="assign_project"
          >
            {fetched && (
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Assign Users to Project"
                defaultValue={projectUsers}
                onChange={handleChange}
                options={users}
                filterOption={(input, option: any) =>
                  (option.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button
            className="btn-link"
            htmlType="reset"
            onClick={() => backToProjectList()}
          >
            Cancel
          </Button>

          <Button
            className="common-btn"
            htmlType="submit"
            disabled={!isFormValid}
          >
            Update Project
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditProjectFormComponent;
