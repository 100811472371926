import React, { useEffect, useState } from 'react';
import { Layout, Button, Table, Modal, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import type { ColumnsType } from 'antd/es/table';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import { useNavigate } from 'react-router-dom';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';

//API
import {
  deleteProject,
  getProjects,
} from '../../../Network/Core/Project/ProjectServices';

//Helper
import AuthUser from '../../../Utils/user';

//CSS
import './ProjectList.css';

import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

const { Content } = Layout;
const { confirm } = Modal;

export const ProjectList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const isUser = AuthUser.getAuthUser().role === 'user';

  useEffect(() => {
    void getProjectsList();
  }, []);

  const navigate = useNavigate();

  const getProjectsList = async (search: string = '') => {
    try {
      const data = (await getProjects(search)).data;
      setProjects(data.projects);
      setIsLoading(false);
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const onEditClick = (email: string) => {
    navigate('/edit-project/' + email);
  };

  const removeProject = async (id: string) => {
    try {
      await deleteProject(id);
      await getProjectsList();
      toast.success('Project removed successfully');
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const showDeleteConfirm = (name: string, id: string) => {
    confirm({
      title: 'Are you sure you want to delete ' + name + '?',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      width: 592,
      centered: true,
      closable: true,
      onOk() {
        void removeProject(id);
      },
      onCancel() {
        console.warn('Cancel');
      },
    });
  };

  const onSearch = (input: string) => {
    void getProjectsList(input);
  };

  interface DataType {
    name: string;
    organization: string;
    id: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        <>
          <span
            className="a-tag"
            onClick={() => viewProject(record.id, record.name)}
          >
            {record.name}
          </span>
        </>
      ),
      className: 'a-tag',
      onCell: (record) => {
        return {
          onClick: () => {
            viewProject(record.id, record.name);
          },
        };
      },
    },
    // {
    //   title: 'Organization',
    //   dataIndex: 'organization',
    //   key: 'organization',
    //   sorter: (a, b) => a.organization.localeCompare(b.organization),
    // },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          {AuthUser.getAuthUser().role !== 'user' && (
            <div className="action-box">
              <Tooltip title="Edit Project">
                <Button
                  className="action-btn"
                  onClick={() => onEditClick(record.id)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Delete Project">
                <Button
                  className="action-btn"
                  onClick={() => showDeleteConfirm(record.name, record.id)}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </div>
          )}
          {/* {AuthUser.getAuthUser().role === 'user' && (
            // <div className="action-box">
            //   <Tooltip title="View Project">
            //     <Button
            //       className="action-btn"
            //       onClick={() => viewProject(record.id, record.name)}
            //     >
            //       <FolderViewOutlined className="view-project-btn" />
            //     </Button>
            //   </Tooltip>
            // </div>
          )} */}
        </>
      ),
    },
  ];

  const userColumns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        <>
          <span
            className="a-tag"
            onClick={() => viewProject(record.id, record.name)}
          >
            {record.name}
          </span>
        </>
      ),
      className: 'a-tag',
      onCell: (record) => {
        return {
          onClick: () => {
            viewProject(record.id, record.name);
          },
        };
      },
    },
  ];

  const viewProject = (id: string, name: string) => {
    navigate('/view-project-details/' + id + '?projectName=' + name);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar
            currentVal={
              AuthUser.getAuthUser().role === 'user'
                ? 'dashboard'
                : 'projects-list'
            }
          />
          <Content>
            <PageContentHeader
              headerTitle="List of Projects"
              showSearchBar={false}
              showButton={AuthUser.getAuthUser().role !== 'user'}
              showStatus={false}
              MapSelect={false}
              onSearch={onSearch}
            />

            <div className="list-table">
              <Table
                columns={isUser ? userColumns : columns}
                tableLayout="auto"
                dataSource={projects}
                rowKey={record => record.id}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
        </Layout>
      </Layout>
    </>
  );
};
