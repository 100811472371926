import axios from 'axios';
import { handleUnAuthorizeError } from '../Utils';
import { useNavigate } from 'react-router-dom';

const getBaseUrl = () => {
  return process.env.REACT_APP_API_BASE_URL;
};

const AUTH_URL = `${getBaseUrl()}/auth`;
const BASE_URL = getBaseUrl();
const timeout = process.env.NODE_ENV === 'development' ? 300000 : 120000;

const api = axios.create({
  timeout,
  headers: { 'Content-Type': 'application/json', Accept: '*/*' },
  withCredentials: false,
  responseType: 'json',
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers = config.headers || {}; // initialize headers if it's undefined
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error),
);

// Response interceptor to refresh token on 401
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const status = await handleUnAuthorizeError(() =>{
        console.log('token failed to refresh')
        const navigate = useNavigate();
        navigate('/');
      }); // Function to refresh token
      if (status == 200) {
        const token = localStorage.getItem('token');
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const refreshToken = async (refresh: string | null) => {
  try {
    return await post(
      `${AUTH_URL}auth/refresh-token`,
      {
        refresh: refresh
      },
      false);
  } catch (error: any) {
    return error.response;
  }
};

const tokenHeaders = async (useToken: boolean) => {
  const token = localStorage.getItem('token');
  return useToken && token !== null
    ? { headers: { Authorization: `${token}` } }
    : {};
};

const uploadTokenHeaders = async (useToken: boolean) => {
  const token = localStorage.getItem('token');
  return useToken && token !== null
    ? {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    : {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
};

export const get = async (url: string, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.get(url, headers);
};

export const downloadFile = async (url: string, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.get(url, {...headers, responseType: 'blob'});
};

export const post = async (url: string, data: any, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.post(url, data, headers);
};

export const upload = async (url: string, data: any, useToken: boolean = true) => {
  const headers = await uploadTokenHeaders(useToken);
  return await api.post(url, data, headers);
};

// export const patch = async (url: string, data: any, useToken: boolean = true) => {
//   const headers = await tokenHeaders(useToken);
//   return await api.patch(url, data, headers);
// };
export const put = async (url: string, data: any, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.put(url, data, headers);
};
// Note: delete is a reserved word
export const remove = async (url: string, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.delete(url, headers);
};

export const patch = async (url: string, data: any, useToken: boolean = true) => {
  const headers = await tokenHeaders(useToken);
  return await api.patch(url, data, headers);
}
