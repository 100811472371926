import AuthLogo from './logo.png';
import Key from './key.png';
import Arrow from './Arrow.png';
import Search from './search.png';
import Edit from './edit-icon.png';
import Delete from './delete-icon.png';
import MailDisabled from './mail-disabled.png';
import LegalName from './legalname.png';
import Address from './address.svg';
import TotalEIn from './total-ein.png';
import TotalPlan from './total-plan.png';
import CardArrowUp from './card-arrow-up.png';
import CardArrowDown from './card-arrow-down.png';
import CalendarIcon from './calender-icon.png';
import ArrowRight from './arrow-right.png';
import AmericanInsurance from './american-insurance.png';
import ElephantInsurance from './elephant-insurance.png';
import Amica from './amica.png';
import GermaniaInsurance from './germania-insurance.png';
import MutualOfOmaha from './mutual-of-omaha.png';
import UnMappedIcon from './unmapped-icon.png';
import DownloadIcon from './download.png';
import UploadIcon from './upload.png';
import CloseIcon from './close-icon.png';
import DownArrowIcon from './down-arrow.png';
import AppAddIcon from './AppaddIcon.png';
import UploadCSVIcon from './uplodCSVIcon.png';
import horizontalLogo from './horizontal-logo.png'


const images = {
  AuthLogo,
  Key,
  Arrow,
  Search,
  Edit,
  Delete,
  MailDisabled,
  LegalName,
  Address,
  TotalEIn,
  TotalPlan,
  CardArrowDown,
  CardArrowUp,
  CalendarIcon,
  ArrowRight,
  AmericanInsurance,
  ElephantInsurance,
  Amica,
  GermaniaInsurance,
  MutualOfOmaha,
  UnMappedIcon,
  UploadIcon,
  DownloadIcon,
  CloseIcon,
  DownArrowIcon,
  AppAddIcon,
  UploadCSVIcon,
  horizontalLogo
};

export default images;
