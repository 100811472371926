import React, { useState } from 'react';
import { Card, Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { EyeFilled, EyeInvisibleFilled, MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { encrypt } from 'n-krypta';

//Components
import FormItemInputComponent from './FormItemInputComponent';

//API
import { login } from '../../Network/Core/Auth/AuthServices';

//Helper
import AuthUser from '../../Utils/user';


interface FormValues {
  email: string;
  password: string;
  overrideOrganization: string | undefined;
}
const LoginCardComponent = ({
  cardTitle,
  validateMessages,
  forgotLink,
}: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    email: '',
    password: '',
    overrideOrganization: undefined,
  });
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);
  const envPasswordPattern: string | undefined =
    process.env.REACT_APP_PASSWORD_REGEX;
  const passwordPattern: string =
    typeof envPasswordPattern === 'string' ? envPasswordPattern : '';

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormValid(false);
    if (formData.email !== '' && formData.password !== '') {
      try {
        const pass = formData.password;
        const data = (await login(formData)).data;
        const jwt_token = 'Bearer ' + data.tokens.accessToken.token;
        localStorage.setItem('token', jwt_token);
        localStorage.setItem(
          'expirationDate',
          data.tokens.accessToken.expirationDate
        );
        localStorage.setItem('token', jwt_token);
        localStorage.setItem(
          'access_code',
          encrypt(pass, AuthUser.getSecret())
        );
        localStorage.setItem(
          'refreshToken',
          data.tokens.refreshToken.token
        );
        localStorage.setItem('user', JSON.stringify(data.user));
        setIsFormValid(true);
        if (data.user.role === 'owner') {
          navigate('/organization-list');
        } else if (data.user.role === 'admin') {
          //admin = organization
          navigate('/projects-list');
        } else {
          navigate('/dashboard');
        }
        toast.success('Login successfully');
      } catch (error: any) {
        setIsFormValid(true);
      }
    }
  };

  return (
    <Card className="Auth_card" style={{ width: '469px' }}>
      <h3>{cardTitle}</h3>
      <Form
        form={form}
        name="nest-messages"
        onSubmitCapture={handleSubmit}
        validateMessages={validateMessages}
      >
        <FormItemInputComponent
          label={null}
          name="email"
          className=""
          onChangeFunction={handleFormChange}
          placeholder="Enter e-mail"
          inputType="text"
          suffix={<MailOutlined />}
          rules={[
            { type: 'email', message: 'Please add proper Email' },
            { required: true, message: 'Email is required!' },
          ]}
        />
        <Form.Item name="Password" rules={[{ required: true }]}>
          <Input.Password
            name="password"
            onChange={handleFormChange}
            placeholder="Enter password"
            iconRender={(visible) =>
              visible ? <EyeFilled /> : <EyeInvisibleFilled />
            }
          />
        </Form.Item>
        <div className="forgotpassword">
          <Link to={forgotLink}>Forgot Password?</Link>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!isFormValid}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginCardComponent;
