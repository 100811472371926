import React, { useEffect } from 'react';
import L from 'leaflet';
import {
  MapContainer,
  GeoJSON,
  useMap,
  TileLayer,
} from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

const MyData = (props: any) => {
  const [data, setData] = React.useState();
  const { selectedDrawing } = props;
  const map = useMap();

  // const location = useLocation();

  const url = selectedDrawing.simplifiedGeojson;

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(url);

      setData(response.data);
    };
    getData().catch(console.error);
  }, []);

  if (data) {
    // These next 3 lines purely for debugging:
    const geojsonObject = L.geoJSON(data);

    map.fitBounds(geojsonObject.getBounds());

    return <GeoJSON data={data} />;
  } else {
    return null;
  }
};

export const Map = (props: any) => {
  const { selectedDrawing } = props;
  return (
    <MapContainer
      style={{ width: '100%', height: '100vh' }}
      zoom={13}
      id="mapId"
      center={[51.505, -0.09]}
      scrollWheelZoom={false}
      fadeAnimation={true}
      markerZoomAnimation={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MyData selectedDrawing={selectedDrawing} />
    </MapContainer>
  );
};