import { apiRoutes } from '../../apiRoutes';
import { get, post, remove } from '../../apiServices';

interface inviteOrganizationInterface {
  name: string;
  adminEmail: string;
}

export const inviteOrganization = async (
  formData: inviteOrganizationInterface,
) => {
  try {
    return await post(`${apiRoutes.organization.invite}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const updateOrganization = async (
  id: string | undefined,
  formData: inviteOrganizationInterface,
) => {
  try {
    return await post(`${apiRoutes.organization.update}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const getOrganizations = async (search: string) => {
  try {
    return await get(
      `${apiRoutes.organization.list}?organization=${search}`,
    );
  } catch (error: any) {
    return error.response;
  }
};

export const getOrganization = async (email: string | undefined) => {
  try {
    return await get(`${apiRoutes.organization.list}/${email}`);
  } catch (error: any) {
    return error.response;
  }
};

export const deleteOrganization = async (name: string | undefined) => {
  try {
    return await remove(`${apiRoutes.organization.delete}/${name}`);
  } catch (error: any) {
    return error.response;
  }
};
