import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

//Components
import FormItemInputComponent from './FormItemInputComponent';

//Helper
import AuthUser from '../../Utils/user';

//API
import { updateProfile } from '../../Network/Core/Auth/AuthServices';

// Images
import images from '../../Assets/Images';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}
const ProfileForm = ({ validateMessages }: any) => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const navigate = useNavigate();

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const [formData, setFormData] = useState<FormValues>({
    firstName: AuthUser.getAuthUser().firstName,
    lastName: AuthUser.getAuthUser().lastName,
    email: AuthUser.getAuthUser().email,
  });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormValid(false);
    if (formData.firstName !== '' && formData.lastName !== '') {
      try {
        await updateProfile(formData);
        const loggedUser = AuthUser.getAuthUser();
        loggedUser.firstName = formData.firstName;
        loggedUser.lastName = formData.lastName;
        localStorage.setItem('user', JSON.stringify(loggedUser));

        toast.success('User profile updated successfully');
        if (loggedUser.role === 'owner') {
          navigate('/organization-list');
        } else if (loggedUser.role === 'admin') {
          navigate('/users-list');
        } else {
          navigate('/dashboard');
        }
      } catch (error: any) {
        toast.error('Something went wrong');
      }
    }
    setIsFormValid(true);
  };
  return (
    <Form
      className="common-form change_pwd_form"
      layout="vertical"
      form={form}
      name="nest-messages"
      onSubmitCapture={onSubmit}
      validateMessages={validateMessages}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <FormItemInputComponent
            label="First Name"
            name="firstName"
            className="firstName"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            rules={[
              { required: true, message: 'First Name is required' },
              {
                max: 15,
                message: 'First Name must be max 15 characters.',
              },
            ]}
            onChangeFunction={handleFormChange}
            initialValues={formData.firstName}
            placeholder="Enter first Name"
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <FormItemInputComponent
            label="Last Name"
            name="lastName"
            className="lastName"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            rules={[
              { required: true, message: 'Last Name is required' },
              {
                max: 15,
                message: 'Last Name must be max 15 characters.',
              },
            ]}
            onChangeFunction={handleFormChange}
            initialValues={formData.lastName}
            placeholder="Enter last Name"
          />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="change-pwd-btn">
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Update Profile
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfileForm;
