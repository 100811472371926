import React, { useEffect, useState } from 'react';
import { Layout, Button, Table, Modal, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import { LoginOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';

//API
import {
  deleteOrganization,
  getOrganizations,
} from '../../../Network/Core/Organization/OrganizationServices';
import { login } from '../../../Network/Core/Auth/AuthServices';

//Helper
import AuthUser from '../../../Utils/user';

//CSS
import './OrganizationList.css';

const { Content } = Layout;
const { confirm } = Modal;

export const OrganizationList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    void getOrganization('');
  }, []);

  const getOrganization = async (search: string) => {
    try {
      const data = (await getOrganizations(search)).data;
      setData(data.organizations);
    } catch (error: any) {
      console.warn(error);
      toast.error('Something went wrong');
    }
    setIsLoading(false);
  };

  const loginAsOrganization = async (name: string) => {
    try {
      const data = (await login({
        email: AuthUser.getAuthUser().email,
        password: AuthUser.getPassword(),
        overrideOrganization: name
      })).data;
      const jwt_token = `Bearer ${data.tokens.accessToken.token}`;

      localStorage.setItem('token', jwt_token);
      localStorage.setItem('expirationDate', data.tokens.accessToken.expirationDate);
      localStorage.setItem('refreshToken', data.tokens.refreshToken.token);

      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('is_owner', '1');

      if (data.user.role === 'owner') {
        navigate('/organization-list');
      } else if (data.user.role === 'admin') {
        //admin = organization
        navigate('/projects-list');
      } else {
        navigate('/dashboard');
      }
      toast.success('Login successfully');
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const onSearch = (input: string) => {
    void getOrganization(input);
  };

  const deleteOrganizations = async (name: string) => {
    await deleteOrganization(name);
    toast.success(name + ' organization deleted successfully');
    await getOrganization('');
  };

  const showDeleteConfirm = (name: string) => {
    confirm({
      title: 'Are you sure you want to delete ' + name + '?',
      //   icon: <ExclamationCircleFilled />,
      //   content: 'Some descriptions',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      width: 592,
      centered: true,
      closable: true,
      onOk() {
        void deleteOrganizations(name);
      },
      onCancel() {
        console.warn('Cancel');
      },
    });
  };

  interface DataType {
    adminEmail: string;
    name: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Admin Email',
      dataIndex: 'adminEmail',
      key: 'adminEmail',
      // render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.adminEmail.localeCompare(b.adminEmail),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <div className="action-box">
            <Tooltip title="Login As Organization">
              <Button
                className="action-btn"
                onClick={() => loginAsOrganization(record.name)}
              >
                <LoginOutlined className="organization-login-btn" />
                {/* <img src={images.Edit} alt="" /> */}
              </Button>
            </Tooltip>
            <Tooltip title="Delete Organization">
              <Button
                className="action-btn"
                onClick={() => showDeleteConfirm(record.name)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="organization-list" />
          <Content>
            <PageContentHeader
              headerTitle="List of Organization"
              showSearchBar={false}
              showButton={true}
              showStatus={false}
              MapSelect={false}
              onSearch={onSearch}
            />
            <div className="list-table">
              <Table
                columns={columns}
                tableLayout="auto"
                dataSource={data}
                rowKey={record => record.name}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
