import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthUser from './Utils/user';
interface Props {
  component: React.ComponentType;
  path?: string;
}

export const AuthRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const isAuthenticated = AuthUser.isAuthenticated();

  if (!isAuthenticated) {
    return <RouteComponent />;
  }

  return <Navigate to={AuthUser.getRedirectPath()} />;
};
