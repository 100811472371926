import React, { useState } from 'react';
// import { AppstoreAddOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Menu, Layout } from 'antd';
import images from '../../Assets/Images';
import {
  UsergroupAddOutlined,
  ProjectOutlined,
  DatabaseOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import AuthUser from '../../Utils/user';

const { Sider } = Layout;

export const getMenus = () => {
  const authUser = AuthUser.getAuthUser();
  const menuList = [];
  if (authUser) {
    if (authUser.role === 'owner') {
      menuList.push({
        label: 'Organization',
        key: 'organization-list',
        icon: <DatabaseOutlined />,
        // icon: <img src={images.AppAddIcon} alt="app-add" />,
      });
    }
    if (authUser.role === 'admin') {
      menuList.push(
        {
          label: 'Projects',
          key: 'projects-list',
          icon: <ProjectOutlined />,
          // disabled: true,
        },
        {
          label: 'Users',
          key: 'users-list',
          icon: <UsergroupAddOutlined />,
          // disabled: true,
        },
      );
    }
    if (authUser.role === 'user') {
      menuList.push({
        label: 'Dashboard',
        key: 'dashboard',
        icon: <DashboardOutlined />,
        // disabled: true,
      });
    }
  }
  return menuList;
};

export const Sidebar = ({ currentVal }: any) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(currentVal);
  const [collapsed, setCollapsed] = useState(false);

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };
  const items: MenuProps['items'] = getMenus();

  return (
    <Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth="80"
      width={200}
      // onBreakpoint={(broken) => {
      //   console.warn(broken);
      // }}
      // onCollapse={(collapsed, type) => {
      //   console.warn(collapsed, type);
      // }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="sidebar-logo">
        <img src={images.AuthLogo} alt="logo" />
      </div>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        items={items}
      />
    </Sider>
  );
};
