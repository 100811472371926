import React, { useState } from 'react';
import { Card, Button, Form, Input } from 'antd';
import { EyeFilled, EyeInvisibleFilled, UserOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Components
import AuthLogoComponent from '../../../Components/CoreComponents/AuthLogoComponent';

//API
import { createUser } from '../../../Network/Core/Auth/AuthServices';

//Images
import images from '../../../Assets/Images';

import '../../AuthModule/Auth.css';
import { ErrorPage } from '../../ErrorPage';

const validateMessages = {
  required: '${name} is required!',
  types: {
    email:
      '$export const Login = (title: any) => {{name} is not a valid email!',
  },
};
interface FormValues {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}

export const NewOrganization = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const envPasswordPattern: string | undefined =
    process.env.REACT_APP_PASSWORD_REGEX;
  const passwordPattern: string =
    typeof envPasswordPattern === 'string' ? envPasswordPattern : '';

  // const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormValues>({
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    setIsFormValid(false);
    try {
      if (
        email &&
        formData.password &&
        formData.firstName &&
        formData.lastName
      ) {
        const params = {
          token: token,
          userInfo: {
            email: email,
            password: formData.password,
            firstName: formData.firstName,
            lastName: formData.lastName
          }
        };
        await createUser(params);
        toast.success('User created successfully.');
        navigate('/');
      }
    } catch (error: any) {
      toast.error('Something went wrong');
    }
    setIsFormValid(true);
  };
  return (
    <>
      {searchParams.get('token') && (
        <>
          <div className="auth_module">
            <AuthLogoComponent />
            <Card className="Auth_card forgot-card" style={{ width: '469px' }}>
              <div className="forgot-icon">
                <img src={images.Key} alt="key" className="key" />
              </div>
              <h3>Sign up</h3>
              {/* <p>Admin has invited you! Create your password.</p> */}
              <Form
                name="user-create-form"
                onSubmitCapture={handleReset}
                validateMessages={validateMessages}
                form={form}
              >
                <Form.Item
                  name="First Name"
                  rules={[
                    { required: true, message: 'First Name is required' },
                    {
                      max: 15,
                      message: 'First Name must be max 15 characters.',
                    },
                  ]}
                >
                  <Input
                    onChange={handleFormChange}
                    name="firstName"
                    placeholder="First name"
                    type="text"
                    suffix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="Last Name"
                  rules={[
                    { required: true, message: 'Last Name is required' },
                    {
                      max: 15,
                      message: 'Last Name must be max 15 characters.',
                    },
                  ]}
                >
                  <Input
                    onChange={handleFormChange}
                    name="lastName"
                    placeholder="Last name"
                    type="text"
                    suffix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="Password"
                  rules={[
                    { required: true },
                    {
                      pattern: new RegExp(passwordPattern),
                      message: process.env.REACT_APP_PASSWORD_MESSAGE,
                    },
                  ]}
                >
                  <Input.Password
                    onChange={handleFormChange}
                    name="password"
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeFilled /> : <EyeInvisibleFilled />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Confirm Password is required!',
                    },
                    () => ({
                      validator(_, passValue) {
                        if (!passValue || formData.password === passValue) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              'The new password that you entered do not match!',
                            ),
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm password"
                    iconRender={(visible) =>
                      visible ? <EyeFilled /> : <EyeInvisibleFilled />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isFormValid}
                  >
                    Register
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </>
      )}
      {!searchParams.get('token') && <ErrorPage />}
    </>
  );
};
