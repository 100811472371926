import React from 'react';
import { Layout } from 'antd';

//Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import OrganizationFormComponent from '../../../Components/CoreComponents/OrganizationFormComponent';

import '../Organization.css';

const { Content } = Layout;

export const AddOrganization = () => {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="organization-list" />
          <Content>
            <PageContentHeader
              headerTitle="Add Organization"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <OrganizationFormComponent FormTitle="add-organization" />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
