import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';
import { getUsers } from '../../Network/Core/Users/UserServices';

//API
import { createProject } from '../../Network/Core/Project/ProjectServices';

//Images
import images from '../../Assets/Images';

interface FormValues {
  name: string | null;
  usersToAdd: Array<string>;
}

const AddProjectFormComponent = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormValues>({
    name: '',
    usersToAdd: selectedUsers,
  });
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  useEffect(() => {
    void getUserList();
  }, []);

  const getUserList = async (search: string = '') => {
    try {
        const data = (await getUsers(search)).data
          if (data.users.length > 0) {
            const options: any = [];
            data.users.map((user: any) =>
              options.push({
                label: user.firstName + ' ' + user.lastName,
                value: user.email,
              }),
            );
            setUsers(options);
            setFetched(true);
          }
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const createProjects = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormValid(false);
    if (formData.name !== '') {
      try {
        await createProject({
          name: formData.name,
          usersToAdd: selectedUsers
        });
        toast.success('Project created successfully');
        navigate('/projects-list');
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.error);
          setIsFormValid(true);
        }
      }
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (value: any) => {
    if (value) {
      setSelectedUsers(value);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createProjects(event);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const backToProjectList = () => {
    navigate('/projects-list');
  };
  return (
    <Form
      className="common-form"
      layout="vertical"
      form={form}
      name="create-project-form"
      onSubmitCapture={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="Project Name"
            name="name"
            className="name"
            placeholder="Enter Project Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            onChangeFunction={handleFormChange}
            rules={[
              { required: true, message: 'Project Name is required' },
              {
                max: 30,
                message: 'Project Name must be max 30 characters.',
              },
            ]}
          />
        </Col>

        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <Form.Item
            label="Assign User"
            name="select User"
            className="assign_project"
          >
            {fetched && (
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Assign Users to Project"
                onChange={handleChange}
                options={users}
                filterOption={(input, option: any) =>
                  (option.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button className="btn-link" onClick={() => backToProjectList()}>
            Cancel
          </Button>

          <Button
            className="common-btn"
            htmlType="submit"
            disabled={!isFormValid}
          >
            Create a Project
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddProjectFormComponent;
