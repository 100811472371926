import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';
import { inviteUser } from '../../Network/Core/Users/UserServices';

import { getProjects } from '../../Network/Core/Project/ProjectServices';

interface FormValues {
  organization: string | null;
  email: string;
  assignedProjects: Array<string>;
}

const AddUserFormComponent = ({ FormTitle }: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    organization: null,
    email: '',
    assignedProjects: [],
  });
  const [projects, setProjects] = useState([]);
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  useEffect(() => {
    void getProjectsList();
  }, []);

  const SendInvitation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.email !== '') {
      setIsFormValid(false);
      try {
        await inviteUser({
          organization: null,
          email: formData.email,
          assignedProjects: formData.assignedProjects
        });
        toast.success('Invitation send successfully');
        navigate('/users-list');
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.error);
        }
      }
      setIsFormValid(true);
    } else {
      toast.error('Fill Required Fields');
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getProjectsList = async (search: string = '') => {
    try {
        const data = (await getProjects(search)).data
          const options: any = [];
          data.projects.map((project: any) =>
            options.push({
              label: project.name,
              value: project.id,
            }),
          );
          setProjects(options);
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    void SendInvitation(event);
  };

  const handleChange = (value: any) => {
    setFormData({
      ...formData,
      ['assignedProjects']: value,
    });
  };

  const backToUserList = () => {
    navigate('/users-list');
  };
  return (
    <Form
      className="common-form"
      layout="vertical"
      form={form}
      onSubmitCapture={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="User Email"
            name="email"
            className=""
            placeholder="Enter User Email"
            inputType="email"
            suffix={<MailOutlined />}
            onChangeFunction={handleFormChange}
            rules={[
              {
                type: 'email',
                message: 'Enter proper User Email',
              },
              {
                required: true,
                message: 'User Email is required',
              },
            ]}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <Form.Item label="Assign Project" className="assign_user">
            {/* {fetched && ( */}
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Assign Project to User"
              onChange={handleChange}
              options={projects}
              filterOption={(input, option: any) =>
                (option.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
            {/* )} */}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button className="btn-link" onClick={() => backToUserList()}>
            Cancel
          </Button>
          {FormTitle === 'add-user' ? (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Send invite
            </Button>
          ) : (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Save
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddUserFormComponent;
