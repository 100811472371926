import { Layout } from 'antd';
import React from 'react';
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import ChangePasswordForm from '../../../Components/CoreComponents/ChangePasswordForm';
import withAuth from '../../../Utils/wrapper';

import '../Organization.css';

const { Content } = Layout;

const validateMessages = {
  required: '${name} is required!',
  passwordsDontMatch: 'Passwords do not match!',
};

function ChangePassword() {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="change-password" />

        <Layout>
          <Sidebar currentVal="change-password" />
          <Content>
            <PageContentHeader
              headerTitle="Change Password"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box change-pwd-contbox">
              <ChangePasswordForm validateMessages={validateMessages} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(ChangePassword);
