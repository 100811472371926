import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';

//API
import { inviteOrganization } from '../../Network/Core/Organization/OrganizationServices';

//Images
import images from '../../Assets/Images';


interface FormValues {
  name: string;
  adminEmail: string;
}

const OrganizationFormComponent = ({ FormTitle }: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    name: '',
    adminEmail: '',
  });
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  const SendInvitation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.name !== '' && formData.adminEmail !== '') {
      setIsFormValid(false);
      try {
        await inviteOrganization(formData)
          toast.success('Invitation send successfully');
        navigate('/organization-list');
      } catch (error: any) {
        toast.error('Something went wrong');
      }
      setIsFormValid(true);
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (FormTitle === 'add-organization') {
      try {
        await SendInvitation(event);
      } catch (error) {
        console.error('Error sending invitation:', error);
      }
    }
  };
  const backToOrganizationList = () => {
    navigate('/organization-list');
  };

  return (
    <Form
      className="common-form"
      layout="vertical"
      form={form}
      onSubmitCapture={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="Organization Name"
            name="name"
            className="name"
            placeholder="Enter Organization Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            onChangeFunction={handleFormChange}
            rules={[
              {
                required: true,
                message: 'Organization Name is required!',
              },
              {
                max: 30,
                message: 'Organization Name must be max 30 characters.',
              },
            ]}
          />
        </Col>

        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="Admin Email"
            name="adminEmail"
            className=""
            placeholder="Enter Admin Email"
            inputType="email"
            suffix={<MailOutlined />}
            onChangeFunction={handleFormChange}
            rules={[
              {
                type: 'email',
                message: 'Enter proper Admin Email',
              },
              {
                required: true,
                message: 'Admin Email is required!',
              },
            ]}
          />
        </Col>
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button
            className="btn-link"
            htmlType="reset"
            onClick={() => backToOrganizationList()}
          >
            Cancel
          </Button>
          {FormTitle === 'add-organization' ? (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Send invite
            </Button>
          ) : (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Save
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};

export default OrganizationFormComponent;
