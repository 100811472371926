import AuthUser from './user';
import axios from 'axios';
import {apiRoutes} from '../Network/apiRoutes';

// Refresh the expired token and made call to the same API
export const handleUnAuthorizeError = async (onError: any) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_BASE_URL + apiRoutes.auth.refreshToken, {
        refreshToken: AuthUser.getLocalRefreshToken()
      });
    AuthUser.updateLocalAccessToken({ data: res.data });
    return res.status;
  } catch (err) {
    console.log(err);
    // failed to refresh token, go to login screen
    localStorage.clear();
    onError();
  }
};
