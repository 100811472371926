import { apiRoutes } from '../../apiRoutes';
import { post, put } from '../../apiServices';

interface changePasswordInterface {
  oldPassword: string;
  newPassword: string;
}
interface setPasswordInterface {
  token: string | null;
  newPassword: string;
  email: string | null;
  confirmPassword: string;
}
interface loginUserInterface {
  email: string;
  password: string;
  overrideOrganization: string | undefined;
}
interface userInfoInterface {
  email: string | null;
  password: string | null;
  firstName: string | null;
  lastName: string | null;
}
interface createUserInterface {
  token: string | null;
  userInfo: userInfoInterface;
}
interface forgotPasswordInterface {
  email: string;
}
interface updateProfileInterface {
  firstName: string;
  lastName: string;
}
interface refreshTokenInterface {
  refreshToken: string | null;
}

export const changePassword = async (formData: changePasswordInterface) => {
  try {
    return await put(`${apiRoutes.auth.changePassword}`, formData, false);
  } catch (error: any) {
    return error.response;
  }
};

export const setPassword = async (formData: setPasswordInterface) => {
  try {
    return await post(`${apiRoutes.auth.setPassword}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const createUser = async (formData: createUserInterface) => {
  try {
    return await post(`${apiRoutes.user.register}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const updateProfile = async (formData: updateProfileInterface) => {
  try {
    return await put(`${apiRoutes.user.update}`, formData);
  } catch (error: any) {
    return error.response;
  }
};

export const forgotPassword = async (formData: forgotPasswordInterface) => {
  try {
    return await post(`${apiRoutes.auth.forgetPassword}`, formData, false);
  } catch (error: any) {
    return error.response;
  }
};

export const login = async (formData: loginUserInterface) => {
  try {
    return await post(`${apiRoutes.auth.login}`, formData, false);
  } catch (error: any) {
    return error.response;
  }
};

export const logOut = async () => {
  try {
    return await post(`${apiRoutes.auth.logout}`, {});
  } catch (error: any) {
    return error.response;
  }
};

export const refreshToken = async (formData: refreshTokenInterface) => {
  try {
    return await post(`${apiRoutes.auth.refreshToken}`, formData, false);
  } catch (error: any) {
    return error.response;
  }
};
