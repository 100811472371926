import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

//Components
import {Sidebar} from '../../../Components/Sidebar/Sidebar';
import {HeaderBar} from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';
import EditFileFormComponent from "../../../Components/CoreComponents/EditFileFormComponent";


//CSS
import '../ProjectView/ProjectDetail.css';

import { useParams } from 'react-router-dom';
import { getFile } from '../../../Network/Core/Directory/DirectoryServices';

const {Content} = Layout;

export const EditFile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState();
    const {id} = useParams()

    useEffect(() => {
        (async () => {
            await getFileDetail()
        })()
    }, []);

    const navigate = useNavigate();

    const getFileDetail = async () => {
        if (id) {
            const data = (await getFile(id)).data;
            setFile(data);
            setIsLoading(false);
        } else {
            toast.error('Something went wrong');
            console.log('error');
            navigate('/view-project-details');
        }
    };
    return (
        <>
            {isLoading && <Loader/>}
            <Layout className="layout_wrapper">
                <HeaderBar activePopupLink=""/>
                <Layout>
                    <Sidebar currentVal="files-list"/>
                    <Content>
                        <PageContentHeader
                            headerTitle="Edit File"
                            showSearchBar={false}
                            showStatus={false}
                            MapSelect={false}
                        />
                        <div className="Main-content-box">
                            {file && (
                                <EditFileFormComponent FormTitle="edit-file" file={file}/>
                            )}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
