import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

//Components
import FormItemInputComponent from './FormItemInputComponent';
import { updateUser } from '../../Network/Core/Users/UserServices';

//Images
import images from '../../Assets/Images';
import { getProjects } from '../../Network/Core/Project/ProjectServices';

interface FormValues {
  email: string;
  firstName: string | null;
  lastName: string | null;
  assignedProjects: Array<string>;
}

const EditUserFormComponent = ({ FormTitle, user }: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    assignedProjects: user.assignedProjects,
  });
  const [projects, setProjects] = useState([]);
  const { email } = useParams();
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);

  useEffect(() => {
    (async () => {
      await getProjectsList();
    })()
    if (user && user.email) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        assignedProjects: user.assignedProjects,
      });
    }
  }, []);

  const getProjectsList = async (search: string = '') => {
    try {
      const data = (await getProjects(search)).data;
      const options: any = [];
      data.projects
        .map((project: any) =>
          options.push({
            label: project.name,
            value: project.id
          })
        );
      setProjects(options);
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  const saveUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formData.email !== '' && email) {
      try {
        setIsFormValid(false);
        await updateUser(formData);
        toast.success('User updated successfully');
        navigate(-1);
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.error);
          setIsFormValid(true);
        }
      }
    } else {
      toast.error('Fill Required Fields');
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    (async () => {
      await saveUser(event);
    })();
  };

  const backToUserList = () => {
    navigate('/users-list');
  };

  const handleChange = (value: any) => {
    setFormData({
      ...formData,
      ['assignedProjects']: value,
    });
  };

  return (
    <Form
      className="common-form"
      layout="vertical"
      form={form}
      onSubmitCapture={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="First Name"
            name="firstName"
            className="firstname"
            placeholder="Enter First Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            onChangeFunction={handleFormChange}
            rules={[
              { required: true, message: 'First Name is required' },
              {
                max: 30,
                message: 'First Name must be max 30 characters.',
              },
            ]}
            initialValues={formData.firstName}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="Last Name"
            name="lastName"
            className="lastName"
            placeholder="Enter Last Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            onChangeFunction={handleFormChange}
            rules={[
              { required: true, message: 'Last Name is required' },
              {
                max: 30,
                message: 'Last Name must be max 30 characters.',
              },
            ]}
            initialValues={formData.lastName}
          />
        </Col>

        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <FormItemInputComponent
            label="User Email"
            name="email"
            className=""
            placeholder="Enter User Email"
            inputType="email"
            suffix={<MailOutlined />}
            onChangeFunction={handleFormChange}
            rules={[
              {
                type: 'email',
                message: 'Enter proper User Email',
              },
            ]}
            initialValues={formData.email}
            disabled={true}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <Form.Item label="Assign Project" className="assign_user">
            {/* {fetched && ( */}
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Assign Project to User"
              onChange={handleChange}
              defaultValue={user.assignedProjects}
              options={projects}
              filterOption={(input, option: any) =>
                (option.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
            {/* )} */}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button className="btn-link" onClick={() => backToUserList()}>
            Cancel
          </Button>
          {FormTitle === 'add-user' ? (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Send invite
            </Button>
          ) : (
            <Button
              className="common-btn"
              htmlType="submit"
              disabled={!isFormValid}
            >
              Save
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditUserFormComponent;
